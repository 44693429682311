import React, { useRef } from "react";
import "./css/Checkout.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/user";
import { toast } from "react-toastify";
import {
  getTaxes,
  getTotalCost,
  getTotalTickets,
  updateTicketCart,
} from "../../redux/ticket";
import { signupVerify } from "../../redux/user";
import store from "../../redux/store";
import validator from "validator";
import { TbUsersPlus } from "react-icons/tb";
import {
  decreaseQuantity,
  increaseQuantity,
  updateQuantity,
} from "../../redux/ticket";
import axios from "axios";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { RxCrossCircled } from "react-icons/rx";
import { devApi } from "../../utils/constants";
import moment from "moment";
import { IoMdArrowRoundBack } from "react-icons/io";
import logomark from "../../Assets/Logomark.svg";
import { FcGoogle } from "react-icons/fc";
// import { FaFacebook } from "react-icons/fa";
import { FaArrowRight, FaCheck, FaCircleCheck } from "react-icons/fa6";
// import { FaApple } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import animationData from "../../Assets/Animation - 1738137172325.json";
import Lottie from "lottie-react";
import Countdown from "react-countdown";

moment.suppressDeprecationWarnings = true;
export default function Checkout() {
  const [loading, setLoading] = useState(false);
  // const [ticketData, setTicketData] = useState([]);
  const [showAnimation, setShowAnimation] = useState(false);
  const [showCouponDiscount, setShowCouponDiscount] = useState(false);
  const { inputValue } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [strength, setStrength] = useState();
  const [, setPassScore] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ticketTypes = useSelector((state) => state.tickets.ticketTypes);
  const totalCost = useSelector((state) => getTotalCost(state));
  const totalTickets = useSelector((state) => getTotalTickets(state));
  // const isEventPast = useSelector((state) => state.tickets.eventDetails?.data?.event?.isPast);
  const token = useSelector((state) => state.user.token);
  const [cancelLoad,] = useState(false);
  const regForEmail = RegExp(
    /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
  );
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const [collapsed, setCollapsed] = useState(true);
  const [showSignup, setShowSignup] = useState(false);
  const [showGuest, setShowGuest] = useState(false);
  const Ref = useRef(null);
  const [, setTyping] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [wrongCoupon, setWrongCoupon] = useState(false);
  const [couponErrorMessage, setCouponErrorMessage] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [proceedLoading, setProceedLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isMinLength, setIsMinLength] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);
  const [couponPercentage, setCouponPercentage] = useState(0);
  const [fixedDiscount, setFixedDiscount] = useState(0);
  const [validTicketId, setValidTicketId] = useState(null);
  const [checkoutSuccess, setCheckoutSuccess] = useState({
    mobileSuccess: false,
    emailSuccess: false,
    agreeSuccess: false,
  });
  const eventData = useSelector((state) => state.tickets.eventDetails);
  // console.log(eventData);

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const fromPage = queryParams.get("from");
  const paymentStatus = queryParams.get("payment");

  useEffect(() => {
    if (!fromPage || (!fromPage.startsWith("event-") && !fromPage.startsWith("paymentFailed"))) {
      navigate("/");
    }
  }, [fromPage, navigate]);

  useEffect(() => {
    if (paymentStatus === "cancel") {
      navigate("/paymentFailed", { replace: true });
    }

    const handleBackNavigation = () => {
      if (paymentStatus === "cancel") {
        navigate("/paymentFailed", { replace: true });
      } else {
        navigate(`/event/${eventData.event.event_id}`);
      }
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackNavigation);

    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, [paymentStatus, navigate, eventData.event.event_id]);

  //signup variables
  const [signupdata, setSignupdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  // login variable
  const [checkoutLogin, setCheckoutLogin] = useState({
    email: "",
    password: ""
  });

  const evaluatePasswordStrength = (password) => {
    let score = 0;

    if (!password) return "";

    // Check password length
    if (password.length > 8) score += 1;
    // Contains lowercase
    if (/[a-z]/.test(password)) score += 1;
    // Contains uppercase
    if (/[A-Z]/.test(password)) score += 1;
    // Contains numbers
    if (/\d/.test(password)) score += 1;
    // Contains special characters
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    setPassScore(score);
    switch (score) {
      case 0:
      case 1:
      case 2:
        return (
          <span ref={Ref} className="weak">
            Weak
          </span>
        );
      case 3:
        return (
          <span ref={Ref} className="medium">
            Medium
          </span>
        );
      case 4:
        return (
          <span ref={Ref} className="strong">
            Strong
          </span>
        );
      case 5:
        return (
          <span ref={Ref} className="very-strong">
            Very Strong
          </span>
        );
      default:
        return null;
    }
  };

  const checkPasswordRequirements = (password) => {
    setIsMinLength(password.length >= 8);
    // Regex for checking the special characters
    setSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(password));
  };

  const signup = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkError()) {
          setLoading(false);
          return;
        }

        // Check if passwords match
        if (signupdata.password !== signupdata.confirm_password) {
          setErrors({ ...errors, confirm_password: "Passwords do not match" });
          toast.error("Passwords do not match");
          setLoading(false);
          return;
        }

        const body = {
          ...signupdata,
        };
        const headers = {
          "Content-Type": "application/json",
        };
        await axios
          .post(`${devApi}/api/registerClient`, body, headers)
          .then((res) => {
            if (res.data.success) {
              store.dispatch(signupVerify(res.data));
              // console.log(res.data.data.email);
              toast.success(`OTP sent to ${res.data.data.email}`);
              navigate("/verifyemail");
            } else if (res.data.error.email[0]) {
              setErrors({ ...errors, email: res.data.error.email[0] });
              toast.error(res.data.error.email[0]);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 450) {
              toast.error("Session Expired, Please log-in again");
              navigate("/login");
            }
          });
      } catch (error) {
        console.error("Signup error:", error);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const checkError = (fieldName = null, value = null) => {
    let dataIsValid = true;
    let newErrors = {};

    const validateField = (name, val) => {
      if (name === "first_name") {
        if (!val.trim()) {
          newErrors.first_name = "First Name is required";
          dataIsValid = false;
        } else if (!validator.isAlpha(val)) {
          newErrors.first_name = "Please enter a valid first name (letters only)";
          dataIsValid = false;
        } else {
          newErrors.first_name = "";
        }
      }

      if (name === "last_name") {
        if (!val.trim()) {
          newErrors.last_name = "Last Name is required";
          dataIsValid = false;
        } else if (!validator.isAlpha(val)) {
          newErrors.last_name = "Please enter a valid last name (letters only)";
          dataIsValid = false;
        } else {
          newErrors.last_name = "";
        }
      }

      if (name === "email") {
        if (!val.trim()) {
          newErrors.email = "Email is required";
          dataIsValid = false;
        } else if (!regForEmail.test(val)) {
          newErrors.email = "Invalid email";
          dataIsValid = false;
        } else {
          newErrors.email = "";
        }
      }

      if (!showGuest && !isLoggedIn) {
        if (name === "password") {
          if (!val.trim()) {
            newErrors.password = "Password is required";
            dataIsValid = false;
          } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(val)) {
            newErrors.password = "Password should contain a special character";
            dataIsValid = false;
          } else if (val.length < 8) {
            newErrors.password = "Password should contain at least 8 characters";
            dataIsValid = false;
          } else {
            newErrors.password = "";
          }
        }

        if (name === "confirm_password") {
          if (!val.trim()) {
            newErrors.confirm_password = "Confirm Password is required";
            dataIsValid = false;
          } else if (signupdata.password !== val) {
            newErrors.confirm_password = "Passwords must match";
            dataIsValid = false;
          } else {
            newErrors.confirm_password = "";
          }
        }
      }
    };

    if (fieldName) {
      validateField(fieldName, value);
    } else {
      Object.keys(signupdata).forEach((key) => validateField(key, signupdata[key]));
    }

    setErrors(newErrors);

    return dataIsValid;
  };

  const updateData = (e) => {
    let { name, value } = e.target;
    value = value.trimStart();

    setSignupdata((prev) => {
      const updatedData = { ...prev, [name]: value };

      // Validate the input and update errors
      const isValid = checkError(name, value);
      if (!isValid) {
        setErrors((prevErrors) => ({ ...prevErrors }));
      }

      return updatedData;
    });
  };

  // useEffect(() => {
  //   if (!isLoggedIn) {
  //     setErrors({
  //       first_name: signupdata.first_name ? "" : "First Name is required",
  //       last_name: signupdata.last_name ? "" : "Last Name is required",
  //       email: signupdata.email ? "" : "Email is required",
  //       password: signupdata.password ? "" : "Password is required",
  //       confirm_password: signupdata.confirm_password
  //         ? signupdata.password === signupdata.confirm_password
  //           ? ""
  //           : "Passwords do not match"
  //         : "Confirm Password is required",
  //     });
  //   }
  // }, []);

  const taxes = useSelector(getTaxes);

  // Google Login:
  const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state.user.user);
  const [unverifiedUser, setUnverifiedUser] = useState();
  const [showVerifyUserLink, setShowVerifyUserLink] = useState(false);
  // const lastPage = useSelector((state) => state.user.lastVisited);

  // useEffect(() => {
  //   console.log("Component rendered");
  // });

  const handleGoogleLogin = () => {
    const redirectUrl = window.location.pathname + window.location.search;
    // console.log("Saving lastPage in query param:", redirectUrl);

    localStorage.setItem("lastPage", redirectUrl);

    if (loginUrlGoogle) {
      window.location.href = `${loginUrlGoogle}&redirect=${encodeURIComponent(redirectUrl)}`;
    }
  };

  useEffect(() => {
    const fetchGoogleLoginUrl = async () => {
      try {
        const response = await fetch(`${devApi}/api/googleAuthLogin`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch Google login URL");
        }

        const data = await response.json();
        setLoginUrlGoogle(data.url);
      } catch (error) {
        console.error("Error fetching Google login URL:", error);
      }
    };

    fetchGoogleLoginUrl();
  }, []);

  useEffect(() => {
    if (validTicketId && ticketTypes.some(ticket => ticket.id === validTicketId && ticket.quantity === 0)) {
      handleClearCoupon();
    }
  }, [ticketTypes, validTicketId]);

  // console.log(eventData);

  const visibleTickets = ticketTypes
    .map((ticket, index) => ({ ...ticket, originalIndex: index }))
    .filter((ticket) => ticket.is_paused !== 1 && ticket.is_hidden !== 1 && ticket.quantity_available !== null);

  const ticketsWithStatus3 = visibleTickets.filter((ticket) => new Date(ticket.start_sale_date).getTime() > Date.now());
  const ticketsWithStatus2 = visibleTickets.filter((ticket) => ticket.sale_status === 2);
  // const ticketsWithStatus1 = visibleTickets.filter((ticket) => ticket.sale_status === 1);

  // Check if all tickets are sale_status: 3
  const allTicketsStatus3 = ticketsWithStatus3.length > 0 && ticketsWithStatus3.length === visibleTickets.length;

  // Check if all tickets are sale_status: 2
  const allTicketsStatus2 = ticketsWithStatus2.length > 0 && ticketsWithStatus2.length === visibleTickets.length;

  // Find the nearest start_sale_date in the future
  const nearestTicket = visibleTickets.reduce((nearest, ticket) => {
    const ticketTime = new Date(ticket.start_sale_date).getTime();
    const currentTime = Date.now();

    if (ticketTime > currentTime && (!nearest || ticketTime < nearest.time)) {
      return { time: ticketTime, ticket };
    }
    return nearest;
  }, null);

  // Handlers for quantity changes
  const handleDecrease = (id) => {
    const index = ticketTypes.findIndex((ticket) => ticket.id === id);
    if (index !== -1) dispatch(decreaseQuantity({ index }));
  };

  const handleIncrease = (id) => {
    const index = ticketTypes.findIndex((ticket) => ticket.id === id);
    if (index !== -1) dispatch(increaseQuantity({ index }));
  };

  const handleQuantityChange = (event, index) => {
    // console.log("Trying to modify index:", index);

    const newQuantity = parseInt(event.target.value) || 0;
    dispatch(updateQuantity({ index, quantity: Math.max(0, newQuantity) }));
  };

  useEffect(() => {
    if (isLoggedIn && user) {
      setErrors({
        first_name: "",
        last_name: "",
        email: "",
      })
      setSignupdata((prev) => ({
        ...prev,
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
      }));
    } else {
      setCheckoutLogin((prev) => ({
        ...prev,
        email: user.email || "",
        password: user.password || "",
      }));
    }
  }, [isLoggedIn, user]);

  const updateLoginData = (e) => {
    setErrors({ email: "", password: "" });
    let { name, value } = e.target;
    value = value.trimStart();

    setCheckoutLogin((prev) => {
      const updateLoginData = { ...prev, [name]: value };
      checkError(name, value, updateLoginData);
      return updateLoginData;
    });
  };

  const validateLoginForm = (fieldName = null, value = null, formData = checkoutLogin) => {
    let newErrors = { ...errors };
    let dataIsValid = true;

    const validateField = (name, val) => {
      if (name === "email") {
        if (!val.trim()) {
          newErrors.email = "Email is required";
          dataIsValid = false;
        } else if (!regForEmail.test(val)) {
          newErrors.email = "Invalid email";
          dataIsValid = false;
        } else {
          newErrors.email = "";
        }
      }

      if (name === "password") {
        if (!val.trim()) {
          newErrors.password = "Password is required";
          dataIsValid = false;
        } else {
          newErrors.password = "";
        }
      }
    };

    if (fieldName) {
      validateField(fieldName, value);
    } else {
      Object.keys(formData).forEach((key) => validateField(key, formData[key]));
    }

    setErrors(newErrors);
    return dataIsValid;
  };

  const logins = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!validateLoginForm()) {
          setLoading(false);
          return;
        }

        await axios
          .post(`${devApi}/api/clientLogin`, checkoutLogin)
          .then((res) => {
            if (res.data.success) {
              dispatch(
                login({
                  token: res.data.token,
                  user: res.data.user,
                  eventId: inputValue,
                })
              );
              toast.success("Login Successful");
            } else {
              setErrors({ ...errors, credentials: res.data.message });
              // toast.error("Incorrect email address or password. Please try again");
              toast.error(`${res.data.message}`);
            }
          })
          .catch((error) => {
            if (error.response.status === 451) {
              setUnverifiedUser(error.response.data.user);
              setShowVerifyUserLink(true);
            }
            setErrors({
              ...errors,
              credentials: error.response.data.message,
            });
          });
      } catch (error) {
        setErrors({ ...errors, credentials: error.response.data.message });
      } finally {
        setErrors({ first_name: "", last_name: "", email: "", password: "", confirm_password: "" });
        setLoading(false);
      }
    }, 1000);
  };

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  //useEffects
  useEffect(() => {
    if (!eventData) {
      isLoggedIn ? navigate("/") : navigate("/checkout");
    }
    if (!eventData.event.terms_conditions || eventData.event.terms_conditions.length === 0) {
      setIsButtonDisabled(false);
      return;
    }
  }, [eventData, isLoggedIn, navigate]);

  //functions-Handlers

  const handleCheckboxChange = (e) => {
    setCheckoutSuccess({
      ...checkoutSuccess,
      agreeSuccess: e.target.checked,
    });
  };

  if (!eventData) {
    return (
      <div className="new-bg d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  if (cancelLoad) {
    return (
      <div className="new-bg d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  if (proceedLoading) {
    return (
      <div className="new-bg d-flex flex-column pt-5 text-center align-items-center ndf">
        {eventData.event.free_event === 0 ? (
          <>
            <span className="redirect-text ">
              You are being redirected to the secured payment gateway
            </span>
            <span className="redirect-back-text">Do not click the "Back" button!</span>
          </>
        ) : (
          <></>
        )}
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  }

  // console.log('ticketTypes:', ticketTypes);

  const checkBoxError = () => {
    let newErrors = { ...errors };
    if (!document.getElementById('inlineRadio1').checked) {
      setIsButtonDisabled(true);
      newErrors.checkbox = "You must agree to the terms and conditions";
    } else {
      setIsButtonDisabled(false);
      newErrors.checkbox = "";
    }

    setErrors(newErrors);
  };

  // API call to apply the coupon
  const handleApplyCoupon = async () => {
    try {
      if (!couponCode.trim()) {
        setWrongCoupon(true);
        setCouponErrorMessage("Please enter a coupon code.");
        setIsCouponApplied(false);
        return;
      }

      const selectedTickets = ticketTypes
        .filter((ticket) => ticket.quantity > 0)
        .map((ticket) => ticket.id);

      if (selectedTickets.length === 0) {
        setWrongCoupon(true);
        setCouponErrorMessage("No valid tickets selected.");
        setIsCouponApplied(false);
        // handleClearCoupon();
        return;
      }

      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const requestBody = {
        coupon: couponCode.toUpperCase(),
        event_id: eventData.event.event_id,
        ticketID: selectedTickets,
      };

      const response = await axios.post(`${devApi}/api/e/applyCoupon`, requestBody, { headers });
      // console.log(response.data);

      if (response.data.message === "Coupon is applied successfully") {
        const { coupon_fee_fixed, coupon_fee_percentage, ticket_id, start_date, end_date } = response.data.couponData;

        const today = new Date();
        const couponStartDate = new Date(start_date);
        const couponEndDate = new Date(end_date);

        if (today < couponStartDate) {
          setWrongCoupon(true);
          setCouponErrorMessage(`Coupon will start on ${moment(start_date).format("DD MMM YYYY")}`);
          setIsCouponApplied(false);
          return;
        }

        if (today > couponEndDate) {
          setWrongCoupon(true);
          setCouponErrorMessage("Coupon Expired.");
          setIsCouponApplied(false);
          return;
        }

        setValidTicketId(ticket_id);

        if (!selectedTickets.includes(ticket_id)) {
          setWrongCoupon(true);
          setCouponErrorMessage(`Coupon ${couponCode} is not valid for selected tickets.`);
          setIsCouponApplied(false);
          return;
        }

        let discount = 0;

        if (parseFloat(coupon_fee_fixed) > 0) {
          discount = parseFloat(coupon_fee_fixed);
          setFixedDiscount(discount);
          setIsCouponApplied(true);
          setCouponPercentage(0);
        } else if (parseFloat(coupon_fee_percentage) > 0) {
          discount = parseFloat(coupon_fee_percentage);
          setCouponPercentage(discount);
          setIsCouponApplied(true);
          setFixedDiscount(0);
        }

        setWrongCoupon(false);
        setShowAnimation(false);
        setShowCouponDiscount(true);
        setTimeout(() => setShowAnimation(true), 10);
      } else {
        setWrongCoupon(true);
        setCouponErrorMessage(response.data.message || "Invalid coupon code. Please try again.");
        setIsCouponApplied(false);
      }
    } catch (error) {
      console.error("Error Occurred in Coupon:", error);
      setWrongCoupon(true);
      setCouponErrorMessage("An error occurred. Please try again.");
      setIsCouponApplied(false);
    }
  };

  const handleClearCoupon = () => {
    setCouponCode('');
    setShowCouponDiscount(false);
    setIsCouponApplied(false);
    setWrongCoupon(false);
    setFixedDiscount(0);
    setCouponPercentage(0);
  };

  const handleCouponChange = (e) => {
    setCouponCode(e.target.value);
    setShowCouponDiscount(false);
    setIsCouponApplied(false);
    setWrongCoupon(false);
  };

  // Calculate total price of valid tickets considering quantity
  const validTicketTotal = ticketTypes
    .filter((ticket) => ticket.id === validTicketId)
    .reduce((total, ticket) => total + (ticket.price * ticket.quantity), 0);

  const totalFixedDiscount = fixedDiscount > 0 ? fixedDiscount * (ticketTypes.find(ticket => ticket.id === validTicketId)?.quantity || 0) : 0;

  const totalPercentageDiscount = validTicketTotal * (parseFloat(couponPercentage || 0) / 100);

  const couponDiscount = totalFixedDiscount > 0 ? totalFixedDiscount : totalPercentageDiscount;

  const finalTotal = totalCost + parseFloat(taxes.processingFee.toFixed(2)) + parseFloat(taxes.bookingFee.toFixed(2)) - couponDiscount;

  const makePayment = async () => {
    // console.log(eventData);
    // console.log(eventData.data);
    // console.log(eventData.event);
    // console.log(eventData.event.event_id);
    setProceedLoading(true);

    let counts = {};
    const ticketData = ticketTypes.map((ticket) => {
      let key = `ticket_${ticket.id}`;
      counts = { ...counts, [key]: ticket.quantity };
      return ticket.id;
    });

    const body = {
      ...counts,
      event_id: eventData.event.event_id,
      tickets: ticketData,
    };

    const selectedTickets = ticketTypes
      .filter((ticket) => ticket.quantity > 0)
      .map((ticket) => ticket.id);

    if (selectedTickets.length === 0) {
      console.error("No valid tickets selected (Quantity must be greater than 1).");
      setWrongCoupon(true);
      setIsCouponApplied(false);
      return;
    }

    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };

    try {
      const response = await axios.post(`${devApi}/api/e/postValidateTickets`, body, headers);

      if (response.data.success) {
        store.dispatch(updateTicketCart(response.data.data));

        const config = {
          headers: {
            Authorization: "Bearer " + token,
            "Content-type": "application/json",
            Accept: "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache",
            Expires: "0",
          },
        };

        const requestBody = {
          ...body,
          data: response.data.data,
          // event_id: eventData.event.event_id,
          first_name: signupdata.first_name,
          last_name: signupdata.last_name,
          email: signupdata.email,
          coupon: couponCode.toUpperCase() || "",
          ticketID: selectedTickets,
        };

        // console.log("Request body of ticket: ", requestBody);

        const apiEndpoint = token
          ? `${devApi}/api/e/ticketPaymentCheckout`
          : `${devApi}/api/e/ticketPaymentCheckoutGuest`;

        const checkoutResponse = await axios.post(apiEndpoint, requestBody, config);
        // console.log(checkoutResponse);

        if (checkoutResponse.data.success) {
          // console.log("Response:", checkoutResponse);
          const { order_type, order_id } = checkoutResponse.data;
          if (order_type) {
            navigate(`/paymentSuccess?session_id=${order_id}`, {
              state: {
                orderType: order_type,
                orderId: order_id,
              },
            });
          } else {
            window.location.replace(checkoutResponse.data.data.url);
          }
        } else {
          setProceedLoading(false);
          setErrors({ ...errors, api: checkoutResponse.data.message });
        }
      } else if (response.data.success === false) {
        const ticketKey = Object.keys(response.data.message)[0];
        // console.log(ticketKey);
        const errorMessages = response.data.message[ticketKey]?.[0] || "Some error occurred";
        // console.log(errorMessages);
        toast.error(`${errorMessages}`);
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        setErrors({ ...errors, api: response.data.message });
      }
    } catch (error) {
      setProceedLoading(false);
      if (error.response && error.response.status === 450) {
        toast.error("Session Expired, Please log-in again");
        navigate("/login");
      } else if (error.request) {
        console.error("No response received from server:", error.request);
        toast.error("Failed to connect to the server. Please try again.");
      } else {
        console.error("Unexpected error:", error.message);
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="new-bg">
      <div className="container p-3 d-flex align-items-center pt-4">
        <button aria-label="Back to Event" className="ms-2 back-to-event border-0 d-flex align-items-center gap-2" onClick={() => navigate(-1)}>
          <IoMdArrowRoundBack size={22} className="first-line-icon" />
          Back to Events
        </button>
      </div>
      <div className="container p-3 pb-4">
        <div className="row gx-4 gy-4">
          {/* Left Section */}
          <div className="col-lg-8 ordersm-1">
            <div className="d-flex flex-column h-100 rounded-bg">
              {isLoggedIn ? (
                <div>
                  {/* Details Section */}
                  <div className="col-12 mx-auto d-flex flex-column order-md-2 my-3 my-lg-0 card rounded-bg border-0">
                    <div className="d-flex flex-column cart h-100 p-3 card p-4 shadow-sm rounded-bg border-0 bg-white">
                      <h3 className="cart-heading">Your Details</h3>
                      <h4 className="cart-description">
                        Your tickets will be sent here
                      </h4>
                      <div className="event-order-box">
                        <div className="row mt-2 px-0">
                          {/* First Name */}
                          <div className="col-lg-6 col-md-6 col-sm-12 mb-3 px-2">
                            <label htmlFor="first_name" className="signup-label">
                              First Name<span className="required-sign">*</span>
                            </label>
                            <input
                              type="text"
                              className="signup-input-name"
                              placeholder="Enter First Name"
                              id="first_name"
                              name="first_name"
                              onChange={(e) => {
                                updateData(e);
                                checkError(e.target.name, e.target.value);
                              }}
                              value={signupdata.first_name}
                              autoComplete="off"
                            />
                            <small className="text-danger ml-2">{errors.first_name}</small>
                          </div>

                          {/* Last Name */}
                          <div className="col-lg-6 col-md-6 col-sm-12 mb-3 px-2">
                            <label htmlFor="last_name" className="signup-label">
                              Last Name<span className="required-sign">*</span>
                            </label>
                            <input
                              type="text"
                              className="signup-input-name"
                              placeholder="Enter Last Name"
                              id="last_name"
                              name="last_name"
                              onChange={(e) => {
                                updateData(e);
                                checkError(e.target.name, e.target.value);
                              }}
                              value={signupdata.last_name}
                              autoComplete="off"
                            />
                            <small className="text-danger ml-2">{errors.last_name}</small>
                          </div>
                        </div>

                        {/* Email */}
                        <div className="row mb-2 px-0">
                          <div className="col-12 px-2">
                            <label htmlFor="email" className="signup-label">
                              Email<span className="required-sign">*</span>
                            </label>
                            <input
                              type="text"
                              className="signup-input"
                              placeholder="Enter Email"
                              id="email"
                              name="email"
                              onChange={(e) => {
                                updateData(e);
                                checkError(e.target.name, e.target.value);
                              }}
                              value={signupdata.email}
                              autoComplete="off"
                            />
                            <small className="text-danger ml-2">{errors.email}</small>
                          </div>
                        </div>
                        {/* <div className="not-you-btn">
                      <button className="not-you-btn" onClick={handleNotYou}>
                        Not You?
                      </button>
                    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              ) : showGuest ? (
                <>
                  <div className="col-lg-12">
                    <div className="card p-4 shadow-sm rounded-bg border-0">
                      <div className="signup-view">
                        <div className="logo d-flex align-items-center">
                          <FiMail size={48} style={{ color: "#E45D09" }} />
                        </div>
                        <div className="my-2">
                          <h4 className="checkout-login-title">Continue as Guest</h4>
                          <div className="checkout-login-desc">Welcome back! Please enter your details.</div>
                        </div>

                        {/* Input Fields */}
                        <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                          {/* Form Container */}
                          <div className="container">
                            <div className="row justify-content-center">
                              <div className="col-12 col-md-6 col-lg-4 w-100">
                                <div className="row">
                                  {/* First Name */}
                                  <div className="col-12 col-md-6 form-group mb-3">
                                    <label htmlFor="first_name" className="signup-label mb-1">
                                      First Name<span className="required-sign">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="first_name"
                                      className="form-control signup-input-name"
                                      placeholder="Enter your first name"
                                      name="first_name"
                                      onChange={updateData}
                                      value={signupdata.first_name}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">{errors.first_name}</small>
                                  </div>

                                  {/* Last Name */}
                                  <div className="col-12 col-md-6 form-group mb-3">
                                    <label htmlFor="last_name" className="signup-label mb-1">
                                      Last Name<span className="required-sign">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="last_name"
                                      className="form-control signup-input-name"
                                      placeholder="Enter your last name"
                                      name="last_name"
                                      onChange={updateData}
                                      value={signupdata.last_name}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">{errors.last_name}</small>
                                  </div>
                                </div>

                                {/* Email */}
                                <div className="form-group mb-3">
                                  <label htmlFor="email" className="signup-label mb-1">
                                    Email<span className="required-sign">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control signup-input-name"
                                    placeholder="Enter your email"
                                    name="email"
                                    onChange={updateData}
                                    value={signupdata.email}
                                    id="email"
                                  />
                                  <small className="text-danger">{errors.email}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="container d-flex justify-content-center align-items-center">
                          <div className="text-center">
                            <h5 className="already-account">
                              Don't have an account?{" "}
                              <button
                                aria-label="Sign Up"
                                onClick={() => {
                                  setShowSignup(true);
                                  setShowGuest(false);

                                }}
                                className="bg-none border-0"
                              >
                                <span className="login-link">Sign up</span>
                              </button>
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="col-lg-12">
                  <div className="card p-4 shadow-sm rounded-bg border-0">
                    {showSignup ? (
                      <div className="signup-view">
                        <div className="logo d-flex align-items-center">
                          <TbUsersPlus size={50} style={{ color: "#E45D09" }} />
                        </div>
                        <div className="my-3">
                          <h4 className="checkout-login-title">Register</h4>
                          <div className="checkout-login-desc">Register for a new account and start booking</div>
                        </div>

                        {/* Input Fields */}
                        <div className="py-3 d-flex flex-column align-items-center justify-content-center">
                          {/* Form Container */}
                          <div className="container">
                            <div className="row justify-content-center">
                              <div className="col-12 col-md-6 col-lg-4 w-100">
                                <div className="row">
                                  {/* First Name */}
                                  <div className="col-12 col-md-6 form-group mb-3">
                                    <label htmlFor="first_name" className="signup-label mb-1">
                                      First Name<span className="required-sign">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="first_name"
                                      className="form-control signup-input-name"
                                      placeholder="Enter your first name"
                                      name="first_name"
                                      onChange={updateData}
                                      value={signupdata.first_name}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">{errors.first_name}</small>
                                  </div>

                                  {/* Last Name */}
                                  <div className="col-12 col-md-6 form-group mb-3">
                                    <label htmlFor="last_name" className="signup-label mb-1">
                                      Last Name<span className="required-sign">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      id="last_name"
                                      className="form-control signup-input-name"
                                      placeholder="Enter your last name"
                                      name="last_name"
                                      onChange={updateData}
                                      value={signupdata.last_name}
                                      autoComplete="off"
                                    />
                                    <small className="text-danger">{errors.last_name}</small>
                                  </div>
                                </div>

                                {/* Email */}
                                <div className="form-group mb-3">
                                  <label htmlFor="email" className="signup-label mb-1">
                                    Email<span className="required-sign">*</span>
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control signup-input-name"
                                    placeholder="Enter your email"
                                    name="email"
                                    onChange={updateData}
                                    value={signupdata.email}
                                    id="email"
                                  />
                                  <small className="text-danger">{errors.email}</small>
                                </div>

                                {/* Password */}
                                <div className="form-group mb-3">
                                  <label
                                    htmlFor="password"
                                    className="signup-label mb-1 d-flex justify-content-between"
                                  >
                                    <span>Password<span className="required-sign">*</span></span>
                                    <span className="text-muted">{strength}</span>
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type={showPassword ? "text" : "password"}
                                      className="form-control signup-input-name pe-5"
                                      placeholder="Create a password"
                                      name="password"
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        updateData(e);
                                        setStrength(evaluatePasswordStrength(value));
                                        checkPasswordRequirements(value);
                                      }}
                                      onFocus={() => setTyping(true)}
                                      onBlur={() => setTyping(false)}
                                      value={signupdata.password}
                                      id="password"
                                    />
                                    <button
                                      type="button"
                                      className="btn position-absolute top-50 end-0 translate-middle-y me-2 p-0 border-0 bg-transparent"
                                      onClick={() => setShowPassword(!showPassword)}
                                    >
                                      {showPassword ? (
                                        <i className="bi bi-eye"></i>
                                      ) : (
                                        <i className="bi bi-eye-slash"></i>
                                      )}
                                    </button>
                                  </div>
                                  <small className="text-danger">{errors.password}</small>
                                </div>

                                {/* {typing && passScore !== 5 && (
                                  <small className="text-primary d-block mt-1">
                                    Use 8 or more characters with a mix of letters, numbers & symbols
                                  </small>
                                )} */}

                                {/* Confirm Password */}
                                <div className="form-group mb-3">
                                  <label
                                    htmlFor="confirm_password"
                                    className="signup-label mb-1 d-flex justify-content-between"
                                  >
                                    <span>Confirm Password<span className="required-sign">*</span></span>
                                  </label>
                                  <div className="position-relative">
                                    <input
                                      type={showConfirmPassword ? "text" : "password"}
                                      className="form-control signup-input-name pe-5"
                                      placeholder="Re-Enter Password"
                                      name="confirm_password"
                                      onChange={(e) => updateData(e)}
                                      value={signupdata.confirm_password}
                                      id="confirm_password"
                                    />
                                    <button
                                      type="button"
                                      className="btn position-absolute top-50 end-0 translate-middle-y me-2 p-0 border-0 bg-transparent"
                                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                    >
                                      {showConfirmPassword ? (
                                        <i className="bi bi-eye"></i>
                                      ) : (
                                        <i className="bi bi-eye-slash"></i>
                                      )}
                                    </button>
                                  </div>
                                  <small className="text-danger">{errors.confirm_password}</small>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* checks the password strength */}
                          <div className="container my-3">
                            <div className="row justify-content-center">
                              <div className="col-12 col-md-4 col-lg-4 w-100 d-flex align-items-center">
                                <div className="d-flex flex-column gap-3 align-items-start">
                                  <div className="d-flex align-items-center w-100">
                                    <FaCircleCheck
                                      size={24}
                                      color={isMinLength ? "green" : "grey"}
                                      className="me-2"
                                    />
                                    <span className="text-muted">Must be at least 8 characters</span>
                                  </div>

                                  <div className="d-flex align-items-center w-100">
                                    <FaCircleCheck
                                      size={24}
                                      color={specialChar ? "green" : "grey"}
                                      className="me-2"
                                    />
                                    <span className="text-muted">Must contain one special character</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Singup Button */}
                        {loading ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <button aria-label="Sign Up" className="signup-btn w-100" type="button" disabled>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          </div>
                        ) : (
                          <div className="container">
                            <div className="d-flex justify-content-center align-items-center mt-2">
                              <div className="w-100">
                                <button
                                  className="btn w-100 get-started signup-btn"
                                  onClick={() => {
                                    const isValid = checkError();
                                    if (!isValid) {
                                      return;
                                    }
                                    signup();
                                  }}
                                >
                                  Get Started
                                </button>
                              </div>
                            </div>
                            <div className="sign-in-options d-flex justify-content-center align-items-center mt-3 mb-1">
                              <button aria-label="Guest checkout" onClick={() => setShowGuest(true)} className="bg-none border-0 d-flex gap-2 align-items-center">
                                <FiMail size={24} />
                                <span className="continue-guest-link" style={{ color: "#344054" }}>Continue as Guest</span>
                              </button>
                            </div>
                          </div>
                        )}
                        <div className="container d-flex justify-content-center align-items-center mt-4">
                          <div className="text-center">
                            <h5 className="already-account text-align-center">
                              Already have an Account?{" "}
                              <button aria-label="Log in" onClick={() => setShowSignup(false)} className="bg-none border-0">
                                <span className="login-link">Log in</span>
                              </button>
                            </h5>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="logo d-flex align-items-center">
                          <img
                            src={logomark}
                            alt="Eventgem"
                            width={40}
                          // className="logomark-border"
                          />
                        </div>
                        <div className="my-3">
                          <h4 className="checkout-login-title">Log in to Your Account</h4>
                          <p className="checkout-login-desc">Save your tickets and personal particulars to make booking tickets easier.</p>
                        </div>
                        <div className="event-order-box px-4">
                          <div className="row mt-2 px-0">
                            {/* Email */}
                            <div className="form-group mb-3">
                              <label htmlFor="email" className="signup-label mb-1">
                                Email<span className="required-sign">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control signup-input-name"
                                placeholder="Enter your email"
                                name="email"
                                onChange={updateLoginData}
                                value={checkoutLogin.email}
                                id="email"
                                autoComplete="off"
                              />
                              <small className="text-danger">{errors.email}</small>
                            </div>

                            {/* Password Input */}
                            <div className="form-group mb-3">
                              <label
                                htmlFor="password"
                                className="signup-label mb-1 d-flex justify-content-between"
                              >
                                <span>Password<span className="required-sign">*</span></span>
                              </label>
                              <div className="position-relative">
                                <input
                                  type={showPassword ? "text" : "password"}
                                  className="form-control signup-input-name pe-5"
                                  placeholder="Enter Password"
                                  name="password"
                                  id="password"
                                  onChange={updateLoginData}
                                  value={checkoutLogin.password}
                                />
                                <button
                                  type="button"
                                  className="btn position-absolute top-50 end-0 translate-middle-y me-2 p-0 border-0 bg-transparent"
                                  onClick={() => setShowPassword(!showPassword)}
                                >
                                  {showPassword ? (
                                    <i className="bi bi-eye"></i>
                                  ) : (
                                    <i className="bi bi-eye-slash"></i>
                                  )}
                                </button>
                              </div>
                              <small className="text-danger">{errors.password}</small>
                            </div>

                            <div className="d-flex justify-content-end align-items-center my-2 px-0 px-sm-0 px-md-auto px-lg-auto">
                              {/* <div className="d-flex gap-2">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  id="forgot-password"
                                  value={checked}
                                  onChange={(e) => {
                                    setChecked(e.target.checked);
                                  }}
                                />
                                <span>Remember for 30 days</span>
                                </div> */}

                              {/* Forgot Password */}
                              <div className="d-flex align-items-center m-0">
                                <Link to="/forgotpassword" className="text-decoration-none">
                                  <h6 className="forgot-text m-0">Forgot Password</h6>
                                </Link>
                              </div>
                            </div>
                            <div className="d-flex my-2 gap-2 align-items-center justify-content-center">
                              {/* Error for Credentials */}
                              {errors.credentials && (
                                <small className="text-danger">
                                  {errors.credentials}
                                </small>
                              )}

                              {/* Verify User Link */}
                              {showVerifyUserLink && (
                                <Link to="/verifyemail" className="text-decoration-none" state={{ ...unverifiedUser }}>
                                  <small className="custom-link-terms">Verify Email Now?</small>
                                </Link>
                              )}
                            </div>

                            {/* Login Button */}
                            {loading ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <button aria-label="Signup" className="signup-btn w-100" type="button" disabled>
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              </div>
                            ) : (
                              <div className="container">
                                <div className="d-flex justify-content-center align-items-center mt-2">
                                  <div className="w-100">
                                    <button
                                      className="btn w-100 get-started"
                                      onClick={() => {
                                        const isValid = validateLoginForm();
                                        if (!isValid) {
                                          return;
                                        }
                                        logins();
                                      }}
                                    >
                                      Log in
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div className="container">
                              <button aria-label="Login-google" className="d-flex align-items-center justify-content-center sign-in-options my-3 w-100">
                                {loginUrlGoogle ? (
                                  <div className="d-flex align-items-center gap-1">
                                    <Link
                                      to="#"
                                      onClick={(e) => { e.preventDefault(); handleGoogleLogin(); }}
                                      className="d-flex align-items-center text-decoration-none"
                                    >
                                      <FcGoogle size={24} />
                                      <span>Continue with Google</span>
                                    </Link>
                                  </div>
                                ) : (
                                  <div className="d-flex justify-content-center align-items-center">
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </div>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="sign-in-options d-flex justify-content-center align-items-center mb-1">
                            <button aria-label="Guest checkout" onClick={() => setShowGuest(true)} className="bg-none border-0 d-flex gap-2 align-items-center">
                              <FiMail size={24} />
                              <span className="continue-guest-link" style={{ color: "#344054" }}>Continue as Guest</span>
                            </button>
                          </div>
                          <div className="container d-flex justify-content-center align-items-center mt-4">
                            <div className="text-center">
                              <h5 className="already-account">
                                Don't have an account?{" "}
                                <button aria-label="Sign Up" onClick={() => setShowSignup(true)} className="bg-none border-0">
                                  <span className="login-link">Sign up</span>
                                </button>
                              </h5>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              {/* Terms and Conditions Section */}
              {(isLoggedIn || showGuest) && (
                <>
                  <div className="col-12 my-3 my-md-4" style={{ boxSizing: "border-box" }}>
                    {eventData.event.terms_conditions?.length > 0 && (
                      <div className="p-4 terms-and-conditions">
                        <div className="d-flex justify-content-between align-items-center cursor-pointer mb-3"
                          onClick={handleToggleClick}
                          aria-expanded={!collapsed}
                          aria-controls="collapse"
                        >
                          <h6 className="m-0 terms-heading">
                            Terms and Conditions <span className="required-sign">*</span>
                          </h6>
                          {collapsed ? <MdKeyboardArrowDown size={20} /> : <MdKeyboardArrowUp size={20} />}
                        </div>

                        <div className={`collapse-content ${!collapsed ? "show" : "collapse"}`}
                          style={{ transition: "height 0.3s ease" }}
                        >
                          <div className="terms-text mt-3 p-3">
                            <ul>
                              {eventData.event.terms_conditions.map((terms, index) => (
                                <li key={index}>{terms}</li>
                              ))}
                            </ul>
                          </div>
                        </div>

                        {/* Checkbox only appears if terms exist */}
                        <div className="d-flex align-items-center mt-3">
                          <input
                            className="form-check-input mt-0 me-2"
                            type="checkbox"
                            id="inlineRadio1"
                            value="option1"
                            onChange={(e) => {
                              handleCheckboxChange(e);
                              checkError();
                              checkBoxError();
                            }}
                            required
                          />
                          <label className="form-check-label my-2" htmlFor="inlineRadio1">
                            By clicking here, I state that I have read and understood the "Terms and Conditions".
                          </label>
                        </div>
                        <small className="text-danger ml-2">{errors.checkbox}</small>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          {/* Tickets Section */}
          <div className="col-lg-4 col-md-12 col-sm-12 ordersm-0 relative">
            <div className="card p-4 shadow-sm rounded-bg border-0">
              {eventData.event.end_date && new Date(eventData.event.end_date) < new Date() ? (
                <div className="sale-status-2 mt-1 text-center">
                  We’re Sorry! This Event Has Ended.
                </div>
              ) : (
                <>
                  {!visibleTickets.every((ticket) => ticket.sale_status === 2 || new Date(ticket.start_sale_date).getTime() > Date.now() || ticket.quantity_available === null || ticket.quantity_available === 0) && (
                    <h4 className="card-heading mb-3">Available Tickets</h4>
                  )}

                  {allTicketsStatus2 ? (
                    <div className="text-center my-4">
                      <div className="sale-starts-title mb-3">We’re Sorry! This Sale Has Ended.</div>
                    </div>
                  ) : (
                    <>
                      {allTicketsStatus3 && nearestTicket ? (
                        <div className="text-center my-4">
                          <div className="sale-starts-title mb-3">Sale Starts in</div>
                          <Countdown
                            date={nearestTicket.time}
                            renderer={({ days, hours, minutes, seconds }) => (
                              <div className="d-flex sale-timer justify-content-center gap-3">
                                <div className="d-flex flex-column align-items-center">
                                  <span className="number">{days}</span>
                                  <span className="desc">Days</span>
                                </div>
                                <div className="number">:</div>
                                <div className="d-flex flex-column align-items-center">
                                  <span className="number">{hours}</span>
                                  <span className="desc">Hours</span>
                                </div>
                                <div className="number">:</div>
                                <div className="d-flex flex-column align-items-center">
                                  <span className="number">{minutes}</span>
                                  <span className="desc">Minutes</span>
                                </div>
                                <div className="number">:</div>
                                <div className="d-flex flex-column align-items-center">
                                  <span className="number">{seconds}</span>
                                  <span className="desc">Seconds</span>
                                </div>
                              </div>
                            )}
                          />
                        </div>
                      ) : (
                        visibleTickets
                          .filter((ticket) => new Date(ticket.start_sale_date).getTime() < Date.now() && ticket.sale_status !== 2)
                          .map((ticket) => (
                            <div className="mb-3" key={ticket.id}>
                              <div className="d-flex justify-content-between align-items-center mb-1 ticket-data gap-2">
                                <div className="d-flex flex-column">
                                  <div className="card-title d-block">{ticket.title}</div>
                                  <div className="cardTitleDes">{ticket.description}</div>
                                </div>
                                {ticket.quantity_sold >= ticket.quantity_available ? (
                                  <div className="d-flex align-items-center">
                                    <span className="sale-status-1">Sold Out</span>
                                  </div>
                                ) : (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <div className="price-data pe-2">
                                        {parseFloat(ticket.price) === 0 ? "Free" : `$${ticket.price}`}
                                      </div>
                                      <div className="ticket-quantity d-flex align-items-center">
                                        <button
                                          disabled={ticket.quantity <= 0 || eventData.event.isPast || ticket.quantity === null || ticket.min_per_person === null}
                                          style={{
                                            opacity:
                                              ticket.quantity <= 0 || eventData.event.isPast || ticket.quantity === null || ticket.min_per_person === null ? 0.5 : 1,
                                          }}
                                          onClick={() => handleDecrease(ticket.id)}
                                        >
                                          -
                                        </button>
                                        <input
                                          type="number"
                                          value={ticket.quantity || 0}
                                          className="text-center border-0 custom-input-number"
                                          style={{ width: "20px", padding: "0", background: "none" }}
                                          onChange={(e) => handleQuantityChange(e, ticket.originalIndex)}
                                          readOnly
                                          min={0}
                                        />
                                        <button
                                          disabled={
                                            eventData.event.isPast ||
                                            ticket.quantity >= ticket.max_per_person ||
                                            ticket.quantity === null ||
                                            ticket.max_per_person === null ||
                                            (ticket.quantity_available - ticket.quantity_sold) === 0 ||
                                            (ticket.quantity + 1) > (ticket.quantity_available - ticket.quantity_sold)
                                          }
                                          style={{
                                            opacity:
                                              eventData.event.isPast ||
                                                ticket.quantity >= ticket.max_per_person ||
                                                ticket.quantity === null ||
                                                ticket.max_per_person === null ||
                                                (ticket.quantity_available - ticket.quantity_sold) === 0 ||
                                                (ticket.quantity + 1) > (ticket.quantity_available - ticket.quantity_sold)
                                                ? 0.5
                                                : 1,
                                          }}
                                          onClick={() => handleIncrease(ticket.id)}
                                        >
                                          +
                                        </button>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div className="px-3">
                                {isCouponApplied && ticket.id === validTicketId && (
                                  <div className="discount-message">
                                    Discount applied! YAY
                                  </div>
                                )}
                              </div>
                            </div>
                          ))
                      )}
                      <div>
                        <div>
                          {(visibleTickets.some(ticket => ticket.quantity > 0) &&
                            visibleTickets.some(ticket => !ticket.is_free) &&
                            visibleTickets.every(ticket => ticket.is_free || ticket.quantity === 0)) && (
                              <div className="text-danger mb-3" style={{ fontSize: "14px" }}>
                                Only free ticket is not allowed!
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {/* Coupons Section */}
              {!visibleTickets.every((ticket) =>
                ticket.sale_status === 1 ||
                ticket.sale_status === 2 ||
                new Date(ticket.start_sale_date).getTime() > Date.now() ||
                ticket.quantity_available === null ||
                ticket.quantity_available === 0 ||
                (eventData?.event.end_date && new Date(eventData.event.end_date) < new Date())
              ) && (
                  <>
                    {showAnimation && (
                      <div
                        className="animation-container d-flex align-items-center justify-content-center"
                        style={{ position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", zIndex: 10 }}
                      >
                        <Lottie animationData={animationData} loop={false} />
                      </div>
                    )}
                    {showAnimation && (
                      <div
                        className="animation-container d-flex align-items-center justify-content-center"
                        style={{ position: "absolute", top: "0", left: "50%", transform: "translateX(-50%)", zIndex: 10 }}
                      >
                        <Lottie animationData={animationData} loop={false} />
                      </div>
                    )}
                    {!visibleTickets.every((ticket) => ticket.is_free) && (
                      <>
                        <div className="coupons-section d-flex mb-2">
                          <div className="d-flex w-100">
                            <div className="coupon-code-container d-flex w-100">
                              <input
                                type="text"
                                className="coupon-code d-flex"
                                placeholder="Coupon Code"
                                value={couponCode.toUpperCase()}
                                onChange={handleCouponChange}
                                autoComplete="off"
                              />
                              {couponCode && (
                                <RxCrossCircled
                                  size={16}
                                  className="coupon-code-clear d-flex"
                                  onClick={handleClearCoupon}
                                  style={{ color: "#98A2B3", cursor: "pointer" }}
                                />
                              )}
                            </div>
                            <span
                              className={`coupon-apply ${isCouponApplied ? 'applied' : ''}`}
                              onClick={!isCouponApplied ? handleApplyCoupon : null}
                              style={isCouponApplied ? { color: "green", cursor: "default" } : {}}
                            >
                              {isCouponApplied ? (
                                <div className="d-flex align-items-center gap-2">
                                  <FaCheck size={16} />
                                  <div>Applied</div>
                                </div>
                              ) : (
                                'Apply'
                              )}
                            </span>
                          </div>
                        </div>

                        {wrongCoupon && <div className="invalid-coupon">{couponErrorMessage}</div>}

                        <div className="coupon-discount d-flex justify-content-between mx-2 my-2">
                          <span className="discount-title">Processing Fee</span>
                          <span className="discount-price">
                            +${taxes?.processingFee?.toFixed(2)}
                          </span>
                        </div>
                        <div className="coupon-discount d-flex justify-content-between mx-2 my-2">
                          <span className="discount-title">Platform Fee</span>
                          <span className="discount-price">
                            +${taxes?.bookingFee?.toFixed(2)}
                          </span>
                        </div>
                      </>
                    )}

                    {showCouponDiscount && (
                      <div className="coupon-discount d-flex justify-content-between mx-2 my-2">
                        <span className="discount-title">Coupon Discount</span>
                        <span className="discount-price">(-${couponDiscount?.toFixed(2)})</span>
                      </div>
                    )}

                    <div className="d-flex justify-content-between align-items-center w-100 mt-3">
                      <div className="total-checkout text-end">
                        Total: ${finalTotal.toFixed(2)}
                      </div>
                      <button
                        className="book-now"
                        disabled={
                          isButtonDisabled ||
                          totalTickets === 0 ||
                          (!isLoggedIn && !showGuest) ||
                          (visibleTickets.some(ticket => ticket.quantity > 0) &&
                            visibleTickets.some(ticket => !ticket.is_free) &&
                            visibleTickets.every(ticket => ticket.is_free || ticket.quantity === 0))
                        }
                        style={{
                          opacity:
                            isButtonDisabled ||
                              totalTickets === 0 ||
                              (!isLoggedIn && !showGuest) ||
                              (visibleTickets.some(ticket => ticket.quantity > 0) &&
                                visibleTickets.some(ticket => !ticket.is_free) &&
                                visibleTickets.every(ticket => ticket.is_free || ticket.quantity === 0))
                              ? 0.8
                              : 1,
                        }}
                        onClick={() => {
                          const errorCheck = checkError();
                          setErrors((prevErrors) => ({ ...prevErrors }));

                          if (!errorCheck) {
                            // console.log("Validation failed, not proceeding with payment.");
                            return;
                          }

                          // console.log("Proceeding to payment...");
                          makePayment();
                        }}
                      >
                        <span>Book Now</span>
                        <FaArrowRight size={18} />
                      </button>
                    </div>
                  </>
                )}
            </div>
          </div>

          {/*Buy Now Fixed Bottom for Mobile */}
          {visibleTickets.length > 0 && (
            <div className="navbar fixed-bottom navbar-light bg-light px-2 d-sm-none py-2">
              {/* Check if the event has ended */}
              {eventData?.event.end_date && new Date(eventData.event.end_date) < new Date() ? (
                <div className="d-flex align-items-center justify-content-center w-100 my-2 text-center">
                  <div className="sale-status-2 text-center">
                    We’re Sorry! This Event Has Ended.
                  </div>
                </div>
              ) : (
                <>
                  {/* Prioritize "Sale Has Ended" message when all tickets have sale_status === 2 */}
                  {visibleTickets.every((ticket) => ticket.sale_status === 2) ? (
                    <div
                      className="d-flex align-items-center justify-content-center w-100 my-2 text-center"
                      style={{ color: "#f98a45", fontWeight: "600" }}
                    >
                      <div className="sale-status-2 mt-1">
                        We’re Sorry! This Sale Has Ended.
                      </div>
                    </div>
                  ) : visibleTickets.every(
                    (ticket) =>
                      ticket.sale_status === 1 || ticket.quantity_available === null || ticket.quantity_available === 0
                  ) ? (
                    <div
                      className="d-flex align-items-center justify-content-center w-100 my-2 text-center"
                      style={{ color: "#f98a45", fontWeight: "600" }}
                    >
                      We’re Sorry! No tickets available for booking
                    </div>
                  ) : visibleTickets.every((ticket) => new Date(ticket.start_sale_date).getTime() > Date.now()) ? (
                    (() => {
                      // Find the nearest start_sale_date
                      const nearestTicket = visibleTickets.reduce((nearest, ticket) => {
                        const ticketTime = new Date(ticket.start_sale_date).getTime();
                        if (!nearest || ticketTime < nearest.time) {
                          return { time: ticketTime, ticket };
                        }
                        return nearest;
                      }, null);

                      if (nearestTicket) {
                        return (
                          <div className="text-center w-100">
                            <div className="sale-start-title mb-3">Event Starts in</div>
                            <Countdown
                              date={nearestTicket.time} // Target start_sale_date
                              renderer={({ days, hours, minutes, seconds }) => (
                                <div className="d-flex sale-timer justify-content-center gap-3">
                                  <div className="d-flex flex-column align-items-center">
                                    <span className="number">{days}</span>
                                    <span className="desc">Days</span>
                                  </div>
                                  <div className="number">:</div>
                                  <div className="d-flex flex-column align-items-center">
                                    <span className="number">{hours}</span>
                                    <span className="desc">Hours</span>
                                  </div>
                                  <div className="number">:</div>
                                  <div className="d-flex flex-column align-items-center">
                                    <span className="number">{minutes}</span>
                                    <span className="desc">Minutes</span>
                                  </div>
                                  <div className="number">:</div>
                                  <div className="d-flex flex-column align-items-center">
                                    <span className="number">{seconds}</span>
                                    <span className="desc">Seconds</span>
                                  </div>
                                </div>
                              )}
                            />
                          </div>
                        );
                      }
                      return null;
                    })()
                  ) : (
                    <>
                      <div className="total d-flex ms-2 mt-0">
                        <div className="total-checkout">
                          Total: ${finalTotal.toFixed(2)}
                        </div>
                      </div>

                      <button
                        className="buy-now-fixed d-flex align-items-center"
                        disabled={
                          isButtonDisabled ||
                          totalTickets === 0 ||
                          (!isLoggedIn && !showGuest) ||
                          (visibleTickets.some(ticket => ticket.quantity > 0) &&
                            visibleTickets.some(ticket => !ticket.is_free) &&
                            visibleTickets.every(ticket => ticket.is_free || ticket.quantity === 0))
                        }
                        style={{
                          opacity:
                            isButtonDisabled ||
                              totalTickets === 0 ||
                              (!isLoggedIn && !showGuest) ||
                              (visibleTickets.some(ticket => ticket.quantity > 0) &&
                                visibleTickets.some(ticket => !ticket.is_free) &&
                                visibleTickets.every(ticket => ticket.is_free || ticket.quantity === 0))
                              ? 0.8
                              : 1,
                        }}
                        onClick={() => {
                          const errorCheck = checkError();
                          setErrors((prevErrors) => ({ ...prevErrors }));

                          if (!errorCheck) {
                            // console.log("Validation failed, not proceeding with payment.");
                            return;
                          }

                          // console.log("Proceeding to payment...");
                          makePayment();
                        }}
                      >
                        <span>Book Now</span>
                        <FaArrowRight size={20} />
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div >
  );
}