import React, { useRef, useState } from "react";
import "./css/Organiser.css";
import ReactGA from "react-ga4";

import { FaArrowRight } from "react-icons/fa6";

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  decreaseQuantity,
  increaseQuantity,
  initializeTicketTypes,
  updateQuantity,
  getTotalTickets,
  getTotalCost,
  updateTicketCart,
  getTaxes,
} from "../../redux/ticket";
import { useEffect } from "react";
import store from "../../redux/store";
import axios from "axios";
import { devApi } from "../../utils/constants";
import { toast } from "react-toastify";
import validator from "validator";
import _ from "lodash";
import { IoMdArrowRoundBack } from "react-icons/io";
const regForEmail = RegExp(
  // /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
  /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
);
export default function DupageTickets({
  data,
  ticketOrder,
  // getLoadFromTicket,
}) {
  const [loading, setLoading] = useState(false);
  const [couponPercentage, setCouponPercentage] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const token = useSelector((state) => state.user.token);
  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
  });
  const [signupdata, setSignupdata] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });
  const navigate = useNavigate();
  const taxes = useSelector(getTaxes);
  // const initialTicketTypes = data.data.tickets.map(ticket => ({
  //   type: ticket.description,
  //   price: parseInt(parseFloat(ticket.price)),
  //   quantity: 1
  // }));
  const ticketTypes = useSelector((state) => state.tickets.ticketTypes);
  const totalCost = useSelector(getTotalCost);
  const totalTickets = useSelector(getTotalTickets);
  const dispatch = useDispatch();
  // const [ticketTypes, setTicketTypes] = useState(initialTicketTypes);
  const [ticketData, setTicketData] = useState([]);
  const { inputValue } = useParams();
  const ticketSummaryRef = useRef(null);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const user = useSelector((state) => state.user.user);

  const [checkoutSuccess, setCheckoutSuccess] = useState({
    mobileSuccess: false,
    emailSuccess: false,
    agreeSuccess: false,
  });

  const [collapsed, setCollapsed] = useState(true);

  const handleToggleClick = () => {
    setCollapsed(!collapsed);
  };

  const handleCheckboxChange = (e) => {
    setCheckoutSuccess({
      ...checkoutSuccess,
      agreeSuccess: e.target.checked,
    });
  };

  // Validations for the input fields:
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  // Validations:
  const handleBookNow = (e) => {
    e.preventDefault();
    if (checkError()) {
      alert("Redirecting to the Payment Page, Please wait and do not refresh your page...");
    } else {
      alert("Please fill in all required fields properly!!!");
    }
  };

  const checkError = () => {
    const newErrors = { ...errors };
    let dataIsValid = true;

    // First Name validation
    if (!signupdata.first_name) {
      dataIsValid = false;
      newErrors.first_name = "First Name is required";
    } else if (validator.isAlpha(signupdata.first_name)) {
      newErrors.first_name = "";
    } else {
      newErrors.first_name = "Please enter a valid first name";
    }

    // Last Name validation
    if (!signupdata.last_name) {
      dataIsValid = false;
      newErrors.last_name = "Last Name is required";
    } else if (validator.isAlpha(signupdata.last_name)) {
      newErrors.last_name = "";
    } else {
      newErrors.last_name = "Please enter a valid last name";
    }

    // Email validation
    if (!signupdata.email) {
      dataIsValid = false;
      newErrors.email = "Email is required";
    } else if (!regForEmail.test(signupdata.email)) {
      dataIsValid = false;
      newErrors.email = "Invalid email";
    } else {
      newErrors.email = "";
    }

    // Phone validation (optional)
    if (signupdata.phone && !validator.isMobilePhone(signupdata.phone)) {
      dataIsValid = false;
      newErrors.phone = "Invalid phone number";
    } else {
      newErrors.phone = "";
    }

    // Checkbox validation
    if (!document.getElementById('inlineRadio1').checked) {
      dataIsValid = false;
      newErrors.checkbox = "You must agree to the terms and conditions";
    } else {
      newErrors.checkbox = "";
    }

    // Update error state
    setErrors(newErrors);

    // Disable or enable the button based on overall validation
    setIsButtonDisabled(!dataIsValid);

    // Return validity status
    return dataIsValid;
  };

  const getLoadFromTicket = (loading) => {
    setLoading(loading);
  };

  const makePayment = async () => {
    if (!checkError()) {
      return;
    }
    setLoading(true);
    getLoadFromTicket(true);

    var counts = {};
    const ticketData = ticketTypes.map((ticket) => {
      var key = `ticket_${ticket.id}`;
      counts = { ...counts, [key]: ticket.quantity };
      return ticket.id;
    });
    const body = {
      ...counts,
      event_id: data.data.event.id,
      tickets: ticketData,
    };
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };
    await axios
      .post(`${devApi}/api/e/postValidateTickets`, body, headers)
      .then((response) => {
        if (response.data.success) {
          ReactGA.gtag("event", "begin_checkout", {
            value: response.data.data.order_total,
            currency: "USD",
            coupon: "",
            items: response.data.data.tickets.map((item) => ({
              item_id: item.ticket.id,
              item_name: item.title,
              affiliation: item.title,
              coupon: "",
              discount: 0,
              index: 0,
              item_brand: "DuPage County Fair",
              price: item.price,
              quantity: item.qty,
            })),
          });
          store.dispatch(updateTicketCart(response.data.data));
          const config = {
            headers: {
              Authorization: "Bearer " + token,
              "Content-type": "application/json",
              Accept: "application/json",
              "Cache-Control": "no-cache",
              Pragma: "no-cache",
              Expires: "0",
            },
          };
          const body = {
            data: response.data.data,
            event_id: data.data.event.id,
            first_name: signupdata.first_name,
            last_name: signupdata.last_name,
            email: signupdata.email,
          };
          axios
            .post(`${devApi}/api/e/ticketPaymentCheckoutGuest`, body, config)
            .then(async (response) => {
              if (response.data.success) {
                window.location.replace(response.data.data.url);
              } else {
                setLoading(false);
                getLoadFromTicket(false);
                setErrors({ ...errors, api: response.data.message });
              }
            })
            .catch((error) => {
              getLoadFromTicket(false);
              setLoading(false);
              if (error.response.status === 450) {
                toast.error("Session Expired, Please log-in again");
                navigate("/login");
              }
            });
        } else {
          setErrors({ ...errors, api: response.data.message });
        }
      })
      .catch((error) => {
        getLoadFromTicket(false);
        setLoading(false);
        if (error.response.status === 450) {
          toast.error("Session Expired, Please log-in again");
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    setTicketData(ticketTypes || []);
  }, [ticketTypes]);

  const handleDecrease = (index) => {
    ReactGA.gtag("event", "view_item", {
      currency: "USD",
      value: (ticketTypes[index].quantity - 1) * ticketTypes[index].price,
      items: [
        {
          item_id: `SKU_${ticketTypes[index].type}`,
          item_name: `${ticketTypes[index].type}`,
          affiliation: "",
          coupon: "",
          discount: 5,
          index: index,
          item_brand: "DuPage County Fair Ticket",
          location_id: "",
          price: ticketTypes[index].price,
          quantity: ticketTypes[index].quantity - 1,
        },
      ],
    });
    ReactGA.gtag("event", "remove_from_cart", {
      currency: "USD",
      value: (ticketTypes[index].quantity - 1) * ticketTypes[index].price,
      items: [
        {
          item_id: `SKU_${ticketTypes[index].type}`,
          item_name: `${ticketTypes[index].type}`,
          affiliation: "",
          coupon: "",
          discount: 5,
          index: index,
          item_brand: "DuPage County Fair Ticket",
          location_id: "",
          price: ticketTypes[index].price,
          quantity: ticketTypes[index].quantity - 1,
        },
      ],
    });
    dispatch(decreaseQuantity({ index }));
  };

  const handleIncrease = (index) => {
    ReactGA.gtag("event", "view_item", {
      currency: "USD",
      value: (ticketTypes[index].quantity + 1) * ticketTypes[index].price,
      items: [
        {
          item_id: `SKU_${ticketTypes[index].type}`,
          item_name: `${ticketTypes[index].type}`,
          affiliation: "",
          coupon: "",
          discount: 5,
          index: index,
          item_brand: "DuPage County Fair Ticket",
          location_id: "",
          price: ticketTypes[index].price,
          quantity: ticketTypes[index].quantity + 1,
        },
      ],
    });
    ReactGA.gtag("event", "add_to_cart", {
      currency: "USD",
      value: (ticketTypes[index].quantity + 1) * ticketTypes[index].price,
      items: [
        {
          item_id: `SKU_${ticketTypes[index].type}`,
          item_name: `${ticketTypes[index].type}`,
          affiliation: "",
          coupon: "",
          discount: 5,
          index: index,
          item_brand: "DuPage County Fair Ticket",
          location_id: "",
          price: ticketTypes[index].price,
          quantity: ticketTypes[index].quantity + 1,
        },
      ],
    });
    dispatch(increaseQuantity({ index }));
  };

  const handleQuantityChange = (event, index) => {
    const newQuantity = parseInt(event.target.value) || 0;
    dispatch(updateQuantity({ index, quantity: newQuantity }));
    // console.log(event);
  };

  useEffect(() => {
    if (isLoggedIn && user) {
      setSignupdata((prev) => ({
        ...prev,
        first_name: user.first_name || "",
        last_name: user.last_name || "",
        email: user.email || "",
      }));
    }
  }, [isLoggedIn, user]);

  const updateData = (e) => {
    const { name, value } = e.target;

    setSignupdata({
      ...signupdata,
      [name]: value[0] === " " ? "" : value,
    });

    setErrors({ ...errors, [name]: "" });

    switch (name) {
      case "first_name":
        setErrors({
          ...errors,
          first_name: value.length === 0 ? "First Name is required" : "",
        });
        break;

      case "last_name":
        setErrors({
          ...errors,
          last_name: value.length === 0 ? "Last Name is required" : "",
        });
        break;

      case "email":
        setErrors({
          ...errors,
          email: value.length === 0 ? "Email is required" : "",
        });
        break;

      default:
        break;
    }
  };

  const handleNotYou = () => {
    // console.log("Not You?");
  };

  if (loading) {
    return (
      <div className="d-flex flex-column justify-content-center text-center align-items-center ndf">
        <span className="redirect-text">
          You are being redirected to the secured payment gateway
        </span>
        <span className="redirect-back-text">Do not click the "Back" button!</span>
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };

  const handleApplyCoupon = () => {
    const code = couponCode;
    if (code === "SAVE20") {
      alert(`Congratulations, Coupon Code ${code} Applied...`)
      setCouponPercentage(20);
    } else if (code === "SAVE10") {
      alert(`Congratulations, Coupon Code ${code} Applied...`)
      setCouponPercentage(10);
    } else if (code === "SAVE50") {
      alert(`Congratulations, Coupon Code ${couponCode} Applied...`);
      setCouponPercentage(50);
    } else {
      setCouponPercentage(0);
      alert("Invalid coupon code");
    }
  };
  const couponDiscount = totalCost * (parseFloat(couponPercentage || 0) / 100);
  const finalTotal = totalCost + taxes.processingFee + taxes.bookingFee - couponDiscount;
  // console.log("Ticket Data:", ticketData);
  // console.log("Taxes:", taxes);
  // console.log("Final Total:", finalTotal);

  return (
    <>
      <div className="new-bg">
        <div className="container p-3 d-flex align-items-center pt-4">
          <button className="ms-2 back-to-event border-0 d-flex align-items-center gap-2" onClick={() => navigate(-1)}>
            <IoMdArrowRoundBack size={22} className="first-line-icon" />
            Back to Events
          </button>
        </div>
        <div className="py-3 pb-4 d-flex flex-column justify-content-center align-items-center">
          <div className="container d-flex flex-lg-row flex-column justify-content-between">
            {/* Right Section: Tickets */}
            <div className="col-lg-4 col-md-12 col-sm-12 order-md-1 order-sm-1 order-lg-2">
              <div className="card p-4 shadow-sm my-sm-0 my-md-0 my-lg-0 my-xl-0 rounded-bg border-0">
                <h4 className="card-heading mb-3">Available Tickets</h4>
                {ticketData.map((ticket, index) => (
                  <div
                    className="d-flex justify-content-between align-items-center mb-3 ticket-data gap-2"
                    key={index}
                  >
                    <div>
                      <span className="card-title d-block">{ticket.title}</span>
                      <span className="card-desc">{ticket.description}</span>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="price-data pe-2">${ticket.price}</div>
                      <div className="ticket-quantity d-flex align-items-center">
                        <button
                          style={{ opacity: ticket.quantity > 0 ? 1 : 0.7 }}
                          onClick={() => handleDecrease(index)}
                        >
                          -
                        </button>
                        <input
                          type="text"
                          value={ticket.quantity || 0}
                          className="form-control text-center border-0"
                          style={{ width: "20px", padding: "0" }}
                          readOnly
                        />
                        <button onClick={() => handleIncrease(index)} >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Coupons Section */}
                <div className="coupons-section d-flex mb-3" >
                  <input
                    type="text"
                    className="coupon-code"
                    placeholder="Coupon Code"
                    value={couponCode}
                    onChange={(e) => setCouponCode(e.target.value)}
                  />
                  <span className="coupon-apply" onClick={handleApplyCoupon}>Apply</span>
                </div>
                <div className="coupon-discount d-flex justify-content-between mx-2 my-2 mb-3">
                  <span className="discount-title">Coupon Discount</span>
                  <span className="discount-price">-${couponDiscount?.toFixed(2)}</span>
                </div>
                <div className="coupon-discount d-flex justify-content-between mx-2 my-2">
                  <span className="discount-title">Processing Fee</span>
                  <span className="discount-price">
                    +${taxes?.processingFee?.toFixed(2)}
                    {/* +${taxes.processingFee ? taxes.processingFee.toFixed(2) : "0.00"} */}
                  </span>
                </div>
                <div className="coupon-discount d-flex justify-content-between mx-2 my-2">
                  <span className="discount-title">Platform Fee</span>
                  <span className="discount-price">
                    +${taxes?.bookingFee?.toFixed(2)}
                    {/* +${taxes.bookingFee ? taxes.bookingFee.toFixed(2) : "0.00"} */}
                  </span>
                </div>
                <div className="total d-flex mx-2 justify-content-end mt-3">Total: ${finalTotal.toFixed(2)}</div>
                <div className="navbar fixed-bottom navbar-light d-flex justify-content-between align-items-center bg-light justify-content-between d-sm-none px-2">
                  <div className="total d-flex mx-2 mt-0 justify-content-end">Total: ${totalCost}</div>
                  {/*Buy Now Fixed Bottom for Mobile Ends*/}
                  <div className="d-flex flex-column align-items-center justify-content-center mx-2">
                    {ticketData && (
                      <button
                        className="buy-now-fixed d-flex gap-2 align-items-center"
                        disabled={isButtonDisabled || totalTickets === 0}
                        style={{ opacity: isButtonDisabled || totalTickets === 0 ? 0.7 : 1 }}
                        onClick={makePayment}
                      >
                        <span>Buy Now</span>
                        <FaArrowRight size={20} />
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end my-3 align-items-center">
                <button
                  className="book-now"
                  disabled={isButtonDisabled || totalTickets === 0}
                  style={{ opacity: isButtonDisabled || totalTickets === 0 ? 0.7 : 1 }}
                  onClick={makePayment}
                >
                  <span>Book Now</span>
                  <FaArrowRight size={18} />
                </button>
              </div>
            </div>

            {/* Left Section: Details and Terms */}
            <div className="col-lg-8 mx-xl-4 mx-lg-4 mx-md-0 mx-sm-0 mx-0 col-md-12 d-flex flex-column order-md-2 order-lg-1 order-1 my-sm-2 my-3 my-lg-0">
              <div className="col-12 d-flex flex-column cart">
                <h3 className="cart-heading">Your Details</h3>
                <h4 className="cart-description">Your tickets will be sent here</h4>
                <div className="event-order-box">
                  <div className="row mb-2 mt-2 px-0">
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3 px-2">
                      <label htmlFor="first_name" className="signup-label">
                        First Name
                        <span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        className="signup-input-name"
                        placeholder="Enter First Name"
                        id="first_name"
                        name="first_name"
                        onChange={(e) => {
                          updateData(e);
                          checkError();
                        }}
                        value={signupdata.first_name}
                        autoComplete="off"
                        required
                      />
                      <small className="text-danger ml-2">{errors.first_name}</small>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 mb-3 px-2">
                      <label htmlFor="formLastName" className="signup-label">
                        Last Name
                        <span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        id="formLastName"
                        className="signup-input-name"
                        placeholder="Enter Last Name"
                        name="last_name"
                        onChange={(e) => {
                          updateData(e);
                          checkError();
                        }}
                        value={signupdata.last_name}
                        autoComplete="off"
                        required
                      />
                      <small className="text-danger ml-2">{errors.last_name}</small>
                    </div>
                  </div>
                  <div className="row mb-2 px-0">
                    <div className="col-12 px-2">
                      <label htmlFor="email" className="signup-label">
                        Email
                        <span className="required-sign">*</span>
                      </label>
                      <input
                        type="text"
                        className="signup-input"
                        placeholder="Enter Email"
                        id="email"
                        name="email"
                        onChange={(e) => {
                          updateData(e);
                          checkError();
                        }}
                        value={signupdata.email}
                        autoComplete="off"
                        required
                      />
                      <small className="text-danger ml-2">{errors.email}</small>
                    </div>
                  </div>
                  <div className="not-you-btn">
                    <button className="not-you-btn" onClick={handleNotYou}>
                      Not You?
                    </button>
                  </div>
                </div>
              </div>

              {/* Terms and Conditions Section */}
              <div className="col-12 my-3 my-md-4" style={{ boxSizing: "border-box" }}>
                <div className="p-4 terms-and-conditions">
                  <div
                    className="d-flex justify-content-between align-items-center cursor-pointer mb-3"
                    onClick={handleToggleClick}
                    aria-expanded={!collapsed}
                    aria-controls="collapseExample"
                  >
                    <h6 className="m-0 terms-heading">Terms and Conditions</h6>
                    {collapsed ? (
                      <MdKeyboardArrowDown className="collapse-icon" size={20} />
                    ) : (
                      <MdKeyboardArrowUp className="collapse-icon" size={20} />
                    )}
                  </div>
                  <div
                    className={`collapse-content ${!collapsed ? "show" : "collapse"}`}
                    style={{ transition: "height 0.3s ease" }}
                  >
                    <div className="terms-text mt-3 p-3">
                      <ul>
                        <li>
                          Cancellation Policy: Tickets are NON-TRANSFERABLE and NON-REFUNDABLE unless the event is canceled or rescheduled.
                        </li>
                        <li>
                          Refund Policy: Refunds are offered only if an event is canceled, rescheduled, or moved. Refunds go back to the original payment mode.
                        </li>
                        <li>
                          In case of event cancellation by the organizer, platform fees and processing fees are NON-REFUNDABLE.
                        </li>
                        <li>
                          If you have any concerns about event cancellations or refunds, please contact the event organizer directly. Only genuine mobile tickets with QR codes will be accepted for entry.
                        </li>
                        <li>
                          Arrive at the event at least 30 minutes prior to avoid rush at the check-in counter.
                        </li>
                        <li>
                          A standard admission ticket provides a single entry to the venue. Once you leave, you cannot re-enter using the same ticket.
                        </li>
                        <li>
                          The tickets purchased are subject to Eventgem’s Terms and Conditions, Privacy Policy, and Cookie Policy.
                        </li>
                        <li>
                          Privacy Policy: Users must review and agree to the privacy policy, which details data handling and security.
                        </li>
                        <li>
                          Prohibited Activities: Users are prohibited from engaging in illegal activities, violating intellectual property rights, or using the service for unauthorized commercial purposes.
                        </li>
                        <li>
                          Disclaimer: Services are provided "as is" without any warranties. Eventgem is not responsible for any third-party links or services.
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* Checkbox Section */}
                  <div className="d-flex align-items-center mt-3">
                    <input
                      className="form-check-input mt-0 me-2"
                      type="checkbox"
                      id="inlineRadio1"
                      value="option1"
                      onChange={(e) => {
                        handleCheckboxChange(e);
                        checkError();
                      }}
                      required
                    />
                    <label
                      className="form-check-label my-2"
                      htmlFor="inlineRadio1"
                    >
                      By clicking here, I state that I have read and understood
                      the "Terms and Conditions".
                    </label>
                  </div>
                  <small className="text-danger ml-2">{errors.checkbox}</small>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="d-flex justify-content-center align-items-center">
            <button
              className={
                totalTickets === 0 ||
                totalCost === 0 ||
                !checkoutSuccess.agreeSuccess ||
                !signupdata.email ||
                !signupdata.first_name ||
                !signupdata.last_name
                  ? "proceedbtn-dis "
                  : "proceedbtn"
              }
              onClick={() => {
                makePayment();
              }}
              disabled={
                totalTickets === 0 ||
                totalCost === 0 ||
                !checkoutSuccess.agreeSuccess ||
                !signupdata.email ||
                !signupdata.first_name ||
                !signupdata.last_name
              }
            >
              {" "}
              Proceed
            </button>
          </div> */}
        </div >
      </div >
    </>
  );
}
