import React, { useEffect, useRef, useState } from "react";
import "./css/Header.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import { RiLogoutCircleRLine } from "react-icons/ri";
import newLogo from "../../Assets/newLogo.svg"
import { useDispatch, useSelector } from "react-redux";
import { logout, doorManagerLogout } from "../../redux/user";
import Modal from "react-bootstrap/Modal";
import { TbCurrentLocation } from "react-icons/tb";
import { IoAddSharp } from "react-icons/io5";
import { FiUser } from "react-icons/fi";
import { HiMenuAlt2, HiMenuAlt3 } from "react-icons/hi";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
// import { BiHomeAlt2 } from "react-icons/bi";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { MdLogin } from "react-icons/md";
import { CiLocationOn } from "react-icons/ci";
// import { PiNotificationBold } from "react-icons/pi";
import { toast } from "react-toastify";
import { IoMdClose, IoMdSearch } from "react-icons/io";
import axios from "axios";
import Select from "react-select";
import { useLoadScript } from "@react-google-maps/api";
// import { IoMdHelpCircleOutline } from "react-icons/io";

export default function HeaderDetails({ searchQuery, setSearchEvent, setSelectedLocation }) {
  const [mobilenavbar, setMobilenavbar] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [geoLocation, setGeoLocation] = useState({});
  const location = useLocation();
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);
  const isDoorManagerPage = ["/doormanager", "/camerascanner"].includes(location.pathname);
  const doorManagerLogin = useSelector((state) => state.user.doorManagerLogin);
  const doorManagerData = useSelector((state) => state.user.doorManagerData);
  const [selectedOption, setSelectedOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [showItems, setShowItems] = useState(true);
  const [city, setCity] = useState("New York");
  const googleApiKey = 'AIzaSyBrtNXfdzBDe5E7ME3DowaF9IhOhoCAxDY';
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleApiKey,
  });
  const user = useSelector((state) => state.user.user);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const notDisplayPage =
    location.pathname === "/login" ||
    location.pathname === "/signup" ||
    location.pathname === "/forgotpassword" ||
    location.pathname === "/resetpassword" ||
    location.pathname === "/verifyemail" ||
    location.pathname === "/doormanagement" ||
    location.pathname === "/organizersignup" ||
    location.pathname === "/organizerlogin" ||
    location.pathname === "/devicescanner" ||
    location.pathname === "/doormanagerdetails" ||
    location.pathname === "/invalidlink";

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const dmValue = searchParams.get("dm");

  const doorManagerLink = useSelector((state) => state.user.doorManagerLink);
  const [selected, setSelected] = useState(null);

  const handleLocationChange = (selectedOption) => {
    setSelectedLocation(selectedOption);
    // setSelected(selectedOption);
    // setSelectedLocation(location); 

    if (selectedOption.value === "use-location") {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const { latitude, longitude } = position.coords;

            try {
              const geocodeResponse = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleApiKey}`
              );

              if (geocodeResponse.data.status === "OK") {
                const cityComponent = geocodeResponse.data.results[0]?.address_components.find((component) =>
                  component.types.includes("locality")
                );

                setCity(cityComponent?.long_name || "City not found");
              } else {
                console.error("Geocoding failed:", geocodeResponse.data.error_message);
                setCity("New York");
              }
            } catch (error) {
              console.error("Error fetching city:", error);
              setCity("New York");
            }
          },
          (error) => {
            if (error.code === error.PERMISSION_DENIED) {
              alert("Please enable location services in your browser settings.");
            } else {
              console.error("Error fetching geolocation:", error.message);
            }
            setCity("New York");
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
        setCity("New York");
      }
    };
  };

  const logouts = () => {
    toast.success("Logout Successfully");
    setMobilenavbar(false);
    setIsDropdownVisible(false);
    setShowModal(false);

    // console.log("Current Path:", location.pathname);
    // console.log("Query Params:", location.search);
    // console.log("Stored dmValue in Redux:", doorManagerLink);

    if (location.pathname === "/dashboard") {
      navigate("/organizerlogin");
    } else if (location.pathname === "/camerascanner") {
      dispatch(doorManagerLogout());

      if (doorManagerLink) {
        navigate(`/doormanagement?dm=${doorManagerLink}`, { replace: true });
      } else {
        navigate("/doormanagement", { replace: true });
      }
    } else {
      dispatch(logout());
      navigate("/login");
    }
  };

  const [selectedLocation, setSelectedLocationCheck] = useState(null);

  const options = [
    { value: "use-location", label: "Use My Location", icon: <TbCurrentLocation size={18} className="flex-shrink" /> },
    { value: "all-locations", label: "All Locations", icon: <CiLocationOn size={18} className="flex-shrink" /> }
  ];

  useEffect(() => {
    if (location.pathname === "/camerascanner" || user.first_name === null || user.last_name === null) {
      setShowItems(false);
    } else {
      setShowItems(true);
    }
  }, [location.pathname, user.first_name, user.last_name]);

  let inputValue = location.pathname.split("/").pop();
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownVisible(false);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      background: "none",
      border: "none",
      boxShadow: "none",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      padding: "0px",
      minWidth: "100px"
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      gap: "5px"
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "5px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      display: "flex",
      alignItems: "center",
      gap: "5px",
      background: isSelected ? "#f8f9fa" : "white",
      color: "#000",
      cursor: "pointer",
      padding: "10px 0",
      fontSize: "14px",
      fontWeight: isSelected ? "bold" : "normal",
      justifyContent: "space-between" // Ensures the checkmark is aligned to the right
    }),
    indicatorSeparator: () => ({
      display: "none" // Hide the vertical separator
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: "#000" // Dropdown arrow color
    })
  };

  useEffect(() => {
    const fetchCityDetails = async () => {
      try {
        if ("geolocation" in navigator) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;

              // console.log("Latitude:", latitude);
              // console.log("Longitude:", longitude);

              const geocodeResponse = await axios.get(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${googleApiKey}`
              );

              if (geocodeResponse.data.status === "OK") {
                const addressComponents =
                  geocodeResponse.data.results[0]?.address_components || [];

                const cityComponent = addressComponents.find((component) =>
                  component.types.includes("locality")
                );

                setCity(cityComponent?.long_name || "City not found");
              } else {
                console.error("Geocoding failed:", geocodeResponse.data.error_message);
                setCity("New York");
              }
            },
            (error) => {
              // console.error("Error fetching geolocation:", error.message);
              setCity("New York");
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
          setCity("New York");
        }
      } catch (error) {
        console.error("Error fetching city:", error);
        setCity("New York");
      }
    };

    fetchCityDetails();
  }, [googleApiKey]);

  useEffect(() => {
    const handleScroll = () => {
      if (location.pathname === "/dashboard") {
        if (window.scrollY > 1000) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      } else {
        if (window.scrollY > 100) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [location.pathname]);

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center align-items-center ndf">
        <div className="loader-box">
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };

  if (loadError) {
    console.error("Error loading Google Maps script:", loadError);
    return <div>Error loading map</div>;
  }

  return (
    <>
      {!notDisplayPage && (
        <>
          {" "}
          <header
            id="header"
            className={
              isSticky ? "header-scrolled forsticky" : "header-scrolled"
            }
          >
            <div className="container d-flex justify-content-between align-items-center py-2">
              <div className="d-flex align-items-start w-100">
                <nav id="navbar" className={mobilenavbar === false ? "navbar" : "navbar-mobile nav-active"}>
                  <ul className="d-flex align-items-center justify-content-between change-justify-between w-100 position-relative">
                    <li className="d-none d-lg-flex gap-2">
                      <h1 className="logo m-auto">
                        <Link to="/">
                          <img
                            src={newLogo}
                            alt="Eventgem Logo"
                            rel="preload"
                            as="image"
                            width={160}
                            className="hide-logo-city"
                          />
                        </Link>
                      </h1>
                      <div className="d-flex justify-content-center align-items-center gap-3 mobile-div">
                        {/* User Location */}
                        {showItems && (
                          <div className="d-flex justify-content-center align-items-center header-select hide-logo-city">
                            {!selected && <CiLocationOn size={23} className="header-event-location" />}
                            <Select
                              options={options}
                              onChange={handleLocationChange}
                              getOptionLabel={(e) => (
                                <div className="d-flex align-items-center">
                                  {e.icon} <span className="ms-2">{e.label}</span>
                                </div>
                              )}
                              formatOptionLabel={(e, { isSelected }) => (
                                <div className="d-flex align-items-center w-100">
                                  {e.icon} <span className="ms-2">{e.label}</span>
                                  {isSelected && <span className="ms-auto">✔</span>}
                                </div>
                              )}
                              placeholder="Choose Location"
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  border: "none", // Remove border
                                  boxShadow: "none", // Remove default box-shadow
                                  background: "transparent",
                                  cursor: "pointer",
                                }),
                              }}
                              className="location-dropdown"
                              isSearchable={false} // Disable typing inside dropdown
                              value={selected}
                            />
                          </div>
                        )}

                        {/* Search Event */}
                        <div className="search-inp-box hide-logo-city position-relative">
                          <IoMdSearch style={{ width: "24px", height: "24px", color: "#667085" }} />
                          <input
                            type="text"
                            className="form-control search-control pe-0"
                            value={searchQuery}
                            style={{ color: "#667085" }}
                            placeholder="Search by event, artist, organizer, or city"
                            name="search-input"
                            autoComplete="off"
                            onChange={(e) => setSearchEvent(e.target.value)}
                          />
                          {searchQuery && (
                            <IoMdClose
                              style={{
                                color: "#667085",
                                fontWeight: "600",
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer"
                              }}
                              onClick={() => setSearchEvent('')}
                            />
                          )}
                        </div>
                      </div>
                    </li>
                    <li className="custom-w-full">
                      <div className="d-flex align-items-center justify-content-end change-justify w-100">
                        <ol className="full-width p-0">
                          <li>
                            <div className="rightBtn d-flex gap-3 align-items-center justify-content-center">
                              {showItems && (
                                <Link to={"https://organizer.eventgem.com/login"} target="_blank" className="event-list-btn list-second">
                                  <span>
                                    <IoAddSharp size={20} />
                                  </span>
                                  List Your Event
                                </Link>
                              )}
                              <div className="drop-down-menu always-visible">
                                <div className="profile-btn">
                                  <span className="profile-icon">
                                    {(isLoggedIn || !isDoorManagerPage) ? (
                                      <span className="d-flex justify-content-center align-items-center rounded-circle profile-letter">
                                        {isDoorManagerPage && doorManagerLogin
                                          ? doorManagerData?.username?.charAt(0).toUpperCase()
                                          : user?.first_name && user?.last_name
                                            ? `${user.first_name.charAt(0).toUpperCase()}${user.last_name.charAt(0).toUpperCase()}`
                                            : user?.username
                                              ? user.username.charAt(0).toUpperCase()
                                              : <FiUser size={23} />}
                                      </span>
                                    ) : (
                                      <FiUser size={23} />
                                    )}
                                  </span>
                                  <div className="d-flex flex-column gap-2">
                                    <span className="name text-nowrap">
                                      {isDoorManagerPage && doorManagerLogin
                                        ? doorManagerData?.username
                                        : isLoggedIn
                                          ? (user?.first_name && user?.last_name)
                                            ? `${user.first_name} ${user.last_name}`
                                            : user?.username || "User"
                                          : "User"}
                                    </span>

                                    {isLoggedIn && !isDoorManagerPage && user?.email && (
                                      <span className="d-none d-email-flex">{user.email}</span>
                                    )}
                                  </div>
                                  <span className="menu-icon hide-logo-city">
                                    <HiMenuAlt3 size={24} />
                                  </span>
                                </div>
                                {showItems && (
                                  <div className="d-none mobile-search-btn justify-content-center align-items-center header-select">
                                    {!selectedLocation && <CiLocationOn size={23} className="header-event-location flex-shrink-0" />}
                                    <Select
                                      options={options}
                                      onChange={(selectedOption) => {
                                        handleLocationChange(selectedOption);
                                        setMobilenavbar(false);
                                      }}
                                      getOptionLabel={(e) => (
                                        <div className="d-flex align-items-center">
                                          {e.icon} <span className="ms-2">{e.label}</span>
                                        </div>
                                      )}
                                      formatOptionLabel={(e, { isSelected }) => (
                                        <div className="d-flex align-items-center w-100">
                                          {e.icon} <span className="ms-2">{e.label}</span>
                                          {isSelected && <span className="ms-auto">✔</span>}
                                        </div>
                                      )}
                                      placeholder="Choose Location"
                                      styles={{
                                        control: (provided) => ({
                                          ...provided,
                                          border: "none",
                                          boxShadow: "none",
                                          background: "transparent",
                                          cursor: "pointer",
                                        }),
                                      }}
                                      className="location-dropdown w-100"
                                      isSearchable={false}
                                    />
                                  </div>
                                )}
                                <hr className="header-hr w-100 p-0 mb-5 d-none" />
                                <ul className="dropdown-menu dropdown-always-visible d-flex align-items-start flex-column gap-2 p-2 h-auto w-100">
                                  {(isLoggedIn && !isDoorManagerPage && showItems) ? (
                                    <>
                                      <li className="d-flex align-items-center justify-content-center gap-2 py-1 hover-filed">
                                        <Link
                                          className="dropdown-item m-0 p-0 d-flex gap-2 align-items-center"
                                          to="/userhome/profile"
                                          onClick={() => setMobilenavbar(false)}
                                        >
                                          <PermIdentityOutlinedIcon style={{ color: "#667085" }} />
                                          View Profile
                                        </Link>
                                      </li>
                                      <li className="d-flex align-items-center justify-content-center gap-2 py-1 hover-filed">
                                        <Link
                                          className="dropdown-item m-0 p-0 d-flex gap-2 align-items-center"
                                          to="/userhome/orderHistory"
                                          onClick={() => setMobilenavbar(false)}
                                        >
                                          <ConfirmationNumberOutlinedIcon style={{ color: "#667085" }} />
                                          My Tickets
                                        </Link>
                                      </li>
                                      <hr className="header-hr w-100 p-0 m-0" />
                                    </>
                                  ) : null}
                                  {/* <li className="d-flex align-items-center gap-2 py-1 hover-filed">
                                    <Link
                                      to={"https://dev.organizer.eventgem.com/login"}
                                      target="_blank"
                                      className="dropdown-item m-0 p-0 d-flex gap-2 align-items-center"
                                      onClick={() => setMobilenavbar(false)}
                                    >
                                      <BiHomeAlt2 size={23} style={{ color: "#667085" }} />
                                      Organizer Portal
                                    </Link>
                                  </li> */}
                                  {/* <li className="d-flex align-items-center gap-2 py-1 hover-filed">
                                <Link
                                  to={"/support"}
                                  target="_blank"
                                  className="dropdown-item m-0 p-0 d-flex gap-2 align-items-center"
                                  onClick={() => setMobilenavbar(false)}
                                >
                                  <IoMdHelpCircleOutline size={23} style={{ color: "#667085" }} />
                                  Support
                                </Link>
                              </li> */}
                                  {/* <hr className="header-hr w-100 p-0 m-0" /> */}
                                  {isLoggedIn || (doorManagerLogin && isDoorManagerPage) ? (
                                    <li className="d-flex align-items-center gap-2 py-1 hover-filed">
                                      <button
                                        className="dropdown-item m-0 p-0 d-flex gap-2 align-items-center"
                                        onClick={() => setShowModal(true)}
                                      >
                                        <LogoutOutlinedIcon style={{ color: "#667085" }} />
                                        Log Out
                                      </button>
                                    </li>
                                  ) : (
                                    <li className="d-flex align-items-center gap-2 py-1 hover-filed">
                                      <Link
                                        className="dropdown-item m-0 p-0 d-flex gap-2 align-items-center"
                                        to={isDoorManagerPage && (doorManagerLink || dmValue) ? `/doormanagement${doorManagerLink || dmValue}` : "/login"}
                                      >
                                        <MdLogin size={23} />
                                        Log In
                                      </Link>
                                      <hr className="header-hr w-100 p-0 mb-2 d-none" />
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </li>
                        </ol>
                      </div>
                    </li>
                    <li className="icon-link d-block d-lg-none px-3">
                      <div className="">
                        {isLoggedIn === true && location.pathname === "/dashboard" && (
                          <ul>
                            <li className="d-block py-2" onClick={() => setShowModal(true)}>
                              <RiLogoutCircleRLine style={{ width: "30px", height: "26px" }} />
                              <span className="logout-cta">Log Out</span>
                            </li>
                          </ul>
                        )}
                      </div>
                    </li>
                    {location.pathname === "/dashboard" ? (
                      <li className="d-none d-lg-flex mb-1 position-relative">
                        {isLoggedIn === true ? (
                          <div className="img-cont">
                            <div onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
                              <FiUser size={25} className="img-fluid" />
                            </div>
                            {isDropdownVisible && (
                              <div ref={dropdownRef} className="img-dropdown">
                                <ul>
                                  <li onClick={() => setShowModal(true)}>Logout</li>
                                </ul>
                              </div>
                            )}
                          </div>
                        ) : (
                          <Link className="login-cta text-white btn btn-primary" to={`/login?inputValue=${inputValue}`}>
                            Login
                          </Link>
                        )}
                      </li>
                    ) : (
                      <></>
                    )}
                    {mobilenavbar === true && (
                      <RxCross2 className="mobile-nav-toggle" onClick={() => setMobilenavbar(false)} />
                    )}
                  </ul>
                  {mobilenavbar === false && (
                    <div className="container nav-active">
                      <div className="d-flex align-items-center gap-2">
                        <HiMenuAlt2 className="mobile-nav-toggle" onClick={() => setMobilenavbar(true)} />
                        <Link to={"/"} className="mobile-nav-logo">
                          <img
                            rel="preload"
                            as="image"
                            className="mobile-outline-none"
                            fetchpriority="high"
                            src={newLogo}
                            alt="Eventgem"
                            width={160}
                          />
                        </Link>
                      </div>
                      <div className="rightSideProfile">
                        {isLoggedIn || doorManagerLogin ? (
                          <span className="d-flex justify-content-center align-items-center rounded-circle profile-letter">
                            {isDoorManagerPage && doorManagerLogin
                              ? doorManagerData?.username?.charAt(0).toUpperCase()
                              : isLoggedIn && user?.first_name && user?.last_name
                                ? `${user?.first_name.charAt(0).toUpperCase()}${user?.last_name.charAt(0).toUpperCase()}`
                                : <FiUser size={23} />}
                          </span>
                        ) : (
                          <FiUser size={23} />
                        )}
                      </div>
                    </div>
                  )}
                </nav>
              </div>
            </div>
          </header>
        </>
      )
      }
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
        size="lg"
        dialogClassName="my-modal p-0"
      >
        <Modal.Body className="p-0">
          <div className="container new-bg rounded">
            <div className="row">
              <div className="col-12 logout-model my-4">
                <div className="d-flex justify-content-between align-items-center p-3">
                  <h3 className="m-0">Are you sure you want to leave?</h3>
                  <button aria-label="Close" onClick={() => setShowModal(false)} className="bg-none border-0">
                    <RxCross2 className="logout-cross" size={24} />
                  </button>
                </div>
                <div className="d-flex justify-content-end align-items-center my-3">
                  <button
                    className="stay-btn"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button aria-label="Logout" className="stay-btn later-btn" onClick={logouts}>
                    Log Out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}