import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./css/Profile.css";
// import "../../Components/EventDetails/css/Header.css";
import Profile from "./Profile";
import OrderHistory from "./OrderHistory";
import { FaBars } from "react-icons/fa6";
// import { RxCross2 } from "react-icons/rx";
import { IoMdArrowRoundBack, IoMdClose, IoMdSearch } from "react-icons/io";
import { FiMail } from "react-icons/fi";
import Notification from "./Notification";

export default function ProfileHome() {
  const [mobilenavbar, setMobilenavbar] = useState(false);
  const [orderHistory, setOrderHistory] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [activeTab, setActiveTab] = useState("Overview");
  const [profile, setProfile] = useState(false);
  const [searchOrder, setSearchOrder] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.includes("profile")) {
      setProfile(true);
      setNotifications(false);
      setOrderHistory(false);
      setActiveTab("Overview");
    }
    if (location.pathname.includes("notification")) {
      setProfile(false);
      setNotifications(true);
      setOrderHistory(false);
      setActiveTab("Notifications");
    }
    if (location.pathname.includes("orderHistory")) {
      setProfile(false);
      setNotifications(false);
      setOrderHistory(true);
      setActiveTab("Orders");
    }
  }, [location.pathname]);

  return (
    <div className="new-bg">
      <div className="container p-3 d-flex align-items-center pt-4">
        <button className="ms-2 back-to-event border-0 d-flex align-items-center gap-2" onClick={() => navigate("/")}>
          <IoMdArrowRoundBack size={22} className="first-line-icon" />
          Back to Events
        </button>
      </div>
      <div className="container px-4 py-1 py-sm-2 py-md-2 py-lg-2">
        <div className="row">
          {/* <div className="col-lg-12 px-3">
            <div className="d-flex gap-3 align-items-center">
              <RiHome3Line size={20} style={{ color: "#667085" }} />
              <FaChevronRight size={15} style={{ color: "#D0D5DD" }} />
              <div className="dashboard-text">Dashboard</div>
              <FaChevronRight size={15} style={{ color: "#D0D5DD" }} />
              <div className="title-text">{activeTab}</div>
            </div>
          </div> */}
          <div className="d-flex justify-content-lg-between align-items-center mt-3 new-bg">
            <div className="page-title w-100 new-bg">
              <header className="d-block d-lg-none drop-down-menu new-bg">
                <div className="container d-flex justify-content-between align-items-center p-0 new-bg">
                  <nav
                    id="navbar"
                    className={
                      mobilenavbar === false
                        ? "navbar-profile"
                        : "navbar-mobile-profile nav-link-active position-relative"
                    }
                  >
                    <div className="d-flex align-items-center gap-2">
                      <FaBars className="data-nav-toggle" size={20} onClick={() => setMobilenavbar(!mobilenavbar)} />
                      <div
                        onClick={() => setMobilenavbar(!mobilenavbar)}
                        className="active-tab-name"
                        style={{ cursor: "pointer", fontWeight: "bold" }}
                      >
                        {activeTab}
                      </div>
                    </div>
                    {mobilenavbar && (
                      <ul className="position-absolute w-100 h-auto">
                        <li>
                          <Link
                            to="/userhome/profile"
                            onClick={() => {
                              setProfile(true);
                              setNotifications(false);
                              setOrderHistory(false);
                              setActiveTab("Overview");
                              setMobilenavbar(false);
                              navigate("/userhome/profile")
                            }}
                          >
                            <span className="d-block py-1">Overview</span>
                          </Link>
                        </li>
                        <hr className="header-hr w-100 p-0 m-0" />
                        {/* <li>
                        <Link
                          to="/userhome/notifications"
                          onClick={() => {
                            setProfile(false);
                            setNotifications(true);
                            setOrderHistory(false);
                            setActiveTab("Notifications");
                            setMobilenavbar(false);
                            navigate("/userhome/notifications")
                          }}
                        >
                          <span className="d-block py-1">Notifications</span>
                        </Link>
                      </li> */}
                        <hr className="header-hr w-100 p-0 m-0" />
                        <li>
                          <Link
                            to="/userhome/orderHistory"
                            onClick={() => {
                              setProfile(false);
                              setNotifications(false);
                              setOrderHistory(true);
                              setActiveTab("Orders");
                              setMobilenavbar(false);
                              navigate("/userhome/orderHistory")
                            }}
                          >
                            <span className="d-block py-1">Orders</span>
                          </Link>
                        </li>
                      </ul>
                    )}
                  </nav>

                  <Link to="mailto:info@eventgem.com" className="contact-support d-flex gap-2 align-items-center text-decoration-none">
                    <FiMail size={18} />
                    <div>Contact Support</div>
                  </Link>
                </div>
                <div className="pt-4 pb-2 new-bg">{activeTab}</div>

                {location.pathname === "/userhome/orderHistory" && (
                  <div className="d-flex justify-content-between align-items-center position-relative">
                    <div className="search-inp-box mb-3 w-100">
                      <IoMdSearch
                        style={{ width: "20px", height: "20px", color: "#667085" }}
                      />
                      <input
                        type="text"
                        className="form-control search-control pe-0"
                        style={{ color: "#667085" }}
                        value={searchOrder}
                        placeholder="Search for orders"
                        name="search-input"
                        onChange={(e) => setSearchOrder(e.target.value)}
                        autoComplete="off"
                      />
                      {searchOrder && (
                        <IoMdClose
                          size={24}
                          style={{
                            color: "#667085",
                            fontWeight: "600",
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer"
                          }}
                          onClick={() => setSearchOrder('')}
                        />
                      )}
                    </div>
                  </div>
                )}
                <div className="col-12 new-bg">
                  {location.pathname === "/userhome/profile" ? (
                    <Profile />
                  ) : location.pathname === "/userhome/notifications" ? (
                    <Notification />
                  ) : (
                    <OrderHistory searchQuery={searchOrder} />
                  )}
                </div>
              </header>
            </div>
          </div>

          <div className="container d-flex justify-content-between align-items-center">
            <div className="d-none d-lg-flex page-title">{activeTab}</div>
            <div className="d-flex align-items-center gap-3 d-lg-flex d-none position-relative">
              {location.pathname === "/userhome/orderHistory" && (
                <div className="d-flex justify-content-between align-items-center position-relative">
                  <div className="search-inp-box w-100">
                    <IoMdSearch
                      style={{ width: "20px", height: "20px", color: "#667085" }}
                    />
                    <input
                      type="text"
                      className="form-control search-control pe-0"
                      style={{ color: "#667085" }}
                      value={searchOrder}
                      placeholder="Search for orders"
                      name="search-input"
                      onChange={(e) => setSearchOrder(e.target.value)}
                      autoComplete="off"
                    />
                    {searchOrder && (
                      <IoMdClose
                        size={24}
                        style={{
                          color: "#667085",
                          fontWeight: "600",
                          position: "absolute",
                          right: "10px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          cursor: "pointer"
                        }}
                        onClick={() => setSearchOrder('')}
                      />
                    )}
                  </div>
                </div>
              )}
              <Link to="mailto:info@eventgem.com" className="contact-support d-none d-lg-flex gap-2 align-items-center text-decoration-none">
                <FiMail size={18} />Contact Support
              </Link>
            </div>
          </div>

          <hr className="my-2 w-100" style={{ border: "1px solid #E4E7EC", opacity: "1" }} />

          <div className="container-fluid my-4 d-none d-lg-flex">
            <div className="row w-100">
              {/* Left side menu */}
              <div className="col-12 col-lg-2 p-0">
                <div>
                  <ul className="d-flex flex-column gap-2 nav-links-overview" type="none">
                    <li
                      className={profile ? "nav-link-active nav-link-overview" : "nav-link-overview"}
                      onClick={() => {
                        setProfile(true);
                        setNotifications(false);
                        setOrderHistory(false);
                        setActiveTab("Overview");
                        navigate("/userhome/profile");
                      }}
                    >
                      Overview
                    </li>
                    {/* <li
                      className={notifications ? "nav-link-active d-flex gap-2 align-items-center nav-link-overview" : "d-flex gap-2 align-items-center nav-link-overview"}
                      onClick={() => {
                        setProfile(false);
                        setNotifications(true);
                        setOrderHistory(false);
                        setActiveTab("Notifications");
                        navigate("/userhome/notifications");
                      }}
                    >
                      Notifications
                      <div className="d-flex justify-content-center align-items-center order-quantity">2</div>
                    </li> */}
                    <li
                      className={orderHistory ? "nav-link-active nav-link-overview" : "nav-link-overview"}
                      onClick={() => {
                        setProfile(false);
                        setNotifications(false);
                        setOrderHistory(true);
                        setActiveTab("Orders");
                        navigate("/userhome/orderHistory");
                      }}
                    >
                      Orders
                    </li>
                  </ul>
                </div>
              </div>

              {/* Right side content */}
              <div className="col-12 col-lg-10 p-3 pt-1">
                <div className="tab-content">
                  {profile && (
                    <div className="tab-pane fade show active" id="pills-profile" role="tabpanel">
                      <Profile />
                    </div>
                  )}

                  {notifications && (
                    <div className="tab-pane fade show active" id="pills-notifications" role="tabpanel">
                      <Notification />
                    </div>
                  )}

                  {orderHistory && (
                    <div className="tab-pane fade show active" id="pills-orders" role="tabpanel">
                      <OrderHistory searchQuery={searchOrder} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}