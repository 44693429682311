import React, { useState, useEffect, useRef } from "react";
import "./css/Signup.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import validator from "validator";
import { signupVerify } from "../../redux/user";
import { useDispatch, useSelector } from "react-redux";
import store from "../../redux/store";
import { devApi } from "../../utils/constants";
import logomark from "../../Assets/Logomark.svg";
import { FaCircleCheck } from "react-icons/fa6";
const regForEmail = RegExp(
  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/
);

export default function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const Ref = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [typing, setTyping] = useState(false);
  const [strength, setStrength] = useState();
  const [passScore, setPassScore] = useState(0);
  const [loginUrlGoogle, setLoginUrlGoogle] = useState(null);
  const [isMinLength, setIsMinLength] = useState(false);
  const [specialChar, setSpecialChar] = useState(false);

  const [signupdata, setSignupdata] = useState({
    first_name: "",
    last_name: "",
    // phone: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // phone: "",
    password: "",
    confirm_password: "",
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetch(`${devApi}/api/googleAuthLogin`, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Something went wrong!");
      })
      .then((data) => setLoginUrlGoogle(data.url))
      .catch((error) => console.error(error));
  }, []);

  const updateData = (e) => {
    const { name, value } = e.target;

    setSignupdata((prev) => {
      const updatedData = { ...prev, [name]: value };
      checkError(name, value);
      return updatedData;
    });
  };

  const checkError = (fieldName = null, value = null) => {
    let newErrors = { ...errors };
    let dataIsValid = true;

    const validateField = (name, val) => {
      if (name === "first_name") {
        if (!val.trim()) {
          newErrors.first_name = "First Name is required";
          dataIsValid = false;
        } else if (!validator.isAlpha(val)) {
          newErrors.first_name = "Please enter a valid first name (letters only)";
          dataIsValid = false;
        } else {
          newErrors.first_name = "";
        }
      }

      if (name === "last_name") {
        if (!val.trim()) {
          newErrors.last_name = "Last Name is required";
          dataIsValid = false;
        } else if (!validator.isAlpha(val)) {
          newErrors.last_name = "Please enter a valid last name (letters only)";
          dataIsValid = false;
        } else {
          newErrors.last_name = "";
        }
      }

      if (name === "email") {
        if (!val.trim()) {
          newErrors.email = "Email is required";
          dataIsValid = false;
        } else if (!regForEmail.test(val)) {
          newErrors.email = "Invalid email";
          dataIsValid = false;
        } else {
          newErrors.email = "";
        }
      }

      if (name === "password") {
        if (!val.trim()) {
          newErrors.password = "Password is required";
          dataIsValid = false;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(val)) {
          dataIsValid = false;
          newErrors.password = "Password should contain special character"
        } else {
          if (val.length >= 8) {
            newErrors.password = "";
          } else {
            dataIsValid = false;
            newErrors.password = "Password should contain minimum 8 characters";
          }
        }
      }

      if (name === "confirm_password") {
        if (!val.trim()) {
          newErrors.confirm_password = "Confirm Password is required";
          dataIsValid = false;
        } else if (signupdata.password !== val) {
          newErrors.confirm_password = "Password must match the original password.";
          dataIsValid = false;
        } else {
          newErrors.confirm_password = "";
        }
      }
    };

    if (fieldName) {
      validateField(fieldName, value);
    } else {
      Object.keys(signupdata).forEach((key) => validateField(key, signupdata[key]));
    }

    setErrors(newErrors);
    return dataIsValid;
  };

  const evaluatePasswordStrength = (password) => {
    let score = 0;

    if (!password) return "";

    // Check password length
    if (password.length > 8) score += 1;
    // Contains lowercase
    if (/[a-z]/.test(password)) score += 1;
    // Contains uppercase
    if (/[A-Z]/.test(password)) score += 1;
    // Contains numbers
    if (/\d/.test(password)) score += 1;
    // Contains special characters
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    setPassScore(score);
    switch (score) {
      case 0:
      case 1:
      case 2:
        return (
          <span ref={Ref} className="weak">
            Weak
          </span>
        );
      case 3:
        return (
          <span ref={Ref} className="medium">
            Medium
          </span>
        );
      case 4:
        return (
          <span ref={Ref} className="strong">
            Strong
          </span>
        );
      case 5:
        return (
          <span ref={Ref} className="very-strong">
            Very Strong
          </span>
        );
      default:
        return null;
    }
  };

  const signup = async (e) => {
    setLoading(true);
    setTimeout(async () => {
      try {
        if (!checkError()) {
          return;
        }

        const body = {
          ...signupdata,
        };
        const headers = {
          "Content-Type": "application/json",
        };
        await axios
          .post(`${devApi}/api/registerClient`, body, headers)
          .then((res) => {
            if (res.data.success) {
              store.dispatch(signupVerify(res.data));
              // console.log(res.data.data.email);
              toast.success(`OTP sent to ${res.data?.data?.email}`);
              navigate("/verifyemail?from=signup");
            } else {
              // console.log(res.data.error.email);
              if (res.data.error.email[0]) {
                setErrors({ ...errors, email: res.data.error.email[0] });
                toast.error(res.data.error.email[0]);
              }
            }
          })
          .catch((error) => {
            if (error?.response?.status === 450) {
              toast.error("Session Expired, Please log-in again");
              navigate("/login");
            }
          });
      } catch (error) {
        console.error("Signup error:", error);
      } finally {
        setLoading(false);
      }
    }, 1000);
  };

  const checkPasswordRequirements = (password) => {
    setIsMinLength(password.length >= 8);
    // Regex for checking the special characters
    setSpecialChar(/[!@#$%^&*(),.?":{}|<>]/.test(password));
  };

  return (
    <div className="new-bg d-flex py-4 justify-content-center align-items-center">
      <div className="container">
        <Link to={"/"} className="logo d-flex justify-content-center align-items-center">
          <img
            src={logomark}
            alt="Eventgem"
            width={60}
          // className="logomark-border"
          />
        </Link>
        <div className="my-4">
          <h4 className="signup-title">Create an Account</h4>
          <div className="signup-desc">Discover, book and manage your tickets</div>
        </div>

        <div className="py-3 d-flex flex-column align-items-center justify-content-center">
          {/* Form Container */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 col-lg-4">
                {/* First Name */}
                <div className="form-group mb-3">
                  <label htmlFor="first_name" className="signup-label mb-1">
                    First Name
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    className="form-control signup-input-field"
                    placeholder="Enter your first name"
                    name="first_name"
                    onChange={updateData}
                    value={signupdata.first_name}
                    autoComplete="off"
                  />
                  <small className="text-danger">{errors.first_name}</small>
                </div>

                {/* Last Name */}
                <div className="form-group mb-3">
                  <label htmlFor="last_name" className="signup-label mb-1">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    className="form-control signup-input-field"
                    placeholder="Enter your last name"
                    name="last_name"
                    onChange={updateData}
                    value={signupdata.last_name}
                    autoComplete="off"
                  />
                  <small className="text-danger ml-2">{errors.last_name}</small>
                </div>

                {/* Email */}
                <div className="form-group mb-3">
                  <label htmlFor="email" className="signup-label mb-1">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control signup-input-field"
                    placeholder="Enter your email"
                    name="email"
                    onChange={updateData}
                    value={signupdata.email}
                    id="email"
                  />
                  <small className="text-danger">{errors.email}</small>
                </div>

                {/* Password */}
                <div className="form-group mb-3">
                  <label
                    htmlFor="password"
                    className="signup-label mb-1 d-flex justify-content-between"
                  >
                    <span>Password</span>
                    <span className="text-muted">{strength}</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control signup-input-field pe-5"
                      placeholder="Create a password"
                      name="password"
                      onChange={(event) => {
                        const value = event.target.value;
                        updateData(event);
                        setStrength(evaluatePasswordStrength(value));
                        checkPasswordRequirements(value);
                      }}
                      onFocus={() => setTyping(true)}
                      onBlur={() => setTyping(false)}
                      value={signupdata.password}
                      id="password"
                    />
                    <button
                      type="button"
                      className="btn position-absolute top-50 end-0 translate-middle-y me-2 p-0 border-0 bg-transparent"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <i className="bi bi-eye"></i>
                      ) : (
                        <i className="bi bi-eye-slash"></i>
                      )}
                    </button>
                  </div>
                  {/* {typing && passScore !== 5 && (
                    <small className="text-primary d-block mt-1">
                      Use 8 or more characters with a mix of letters, numbers & symbols
                    </small>
                  )} */}
                  <small className="text-danger">{errors.password}</small>
                </div>

                {/*Confirm Password*/}
                <div className="form-group mb-3">
                  <label
                    htmlFor="confirm_password"
                    className="signup-label mb-1 d-flex justify-content-between"
                  >
                    <span>Confirm Password</span>
                  </label>
                  <div className="position-relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control signup-input-field pe-5"
                      placeholder="Re-Enter Password"
                      name="confirm_password"
                      onChange={updateData}
                      value={signupdata.confirm_password}
                      id="confirm_password"
                    />
                    <button
                      type="button"
                      className="btn position-absolute top-50 end-0 translate-middle-y me-2 p-0 border-0 bg-transparent"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      {showConfirmPassword ? (
                        <i className="bi bi-eye"></i>
                      ) : (
                        <i className="bi bi-eye-slash"></i>
                      )}
                    </button>
                  </div>
                  <small className="text-danger">{errors.confirm_password}</small>
                </div>
              </div>
            </div>
          </div>

          {/* checks the password strength */}
          <div className="container my-3">
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 col-lg-4">
                <div className="d-flex flex-column gap-3 align-items-start">
                  <div className="d-flex align-items-center w-100">
                    <FaCircleCheck
                      size={24}
                      color={isMinLength ? "green" : "grey"}
                      className="me-2"
                    />
                    <span className="text-muted">Must be at least 8 characters</span>
                  </div>

                  <div className="d-flex align-items-center w-100">
                    <FaCircleCheck
                      size={24}
                      color={specialChar ? "green" : "grey"}
                      className="me-2"
                    />
                    <span className="text-muted">Must contain one special character</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading === true ? (
          <div className="d-flex justify-content-center align-items-center">
            <button className="signup-btn w-100" type="button" disabled style={{ maxWidth: '400px' }}>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        ) : (
          <div className="container">
            <div className="d-flex justify-content-center align-items-center mt-2">
              <div className="w-100" style={{ maxWidth: '400px' }}>
                <button
                  className="btn w-100 get-started"
                  onClick={() => {
                    const isValid = checkError();
                    if (!isValid) {
                      return
                    }
                    signup();
                  }}
                >
                  Get Started
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="container d-flex justify-content-center align-items-center mt-3">
          <div className="text-center">
            <h5 className="already-account">
              Already have an Account?{" "}
              <Link to="/login">
                <span className="login-link">Log in</span>
              </Link>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}
